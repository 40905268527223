// import "./service.css";
// import { BiCheck } from "react-icons/bi";
// import AOS from "aos";
// import "aos/dist/aos.css";

// AOS.init();

// const Service = () => {
//   const serviceContainerStyle = {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     minHeight: "100vh",
//     padding: "20px",
//   };

//   const hrStyle = {
//     width: "100%",
//     margin: "20px 0px",
//   };

//   return (
//     <div style={serviceContainerStyle} id="learn">
//       <div data-aos="fade-up">
//         <h1 style={{ fontFamily: 'Instagram Sans Bold' }} className="md:text-5xl text-2xl">Learn with Us</h1>
//       </div>
//       <div style={hrStyle}>
//         <hr />
//       </div>
//       <div>
//         <section id="services">
//           <div className="container services__container md:mr-40">
//             <article
//               className="service"
//               data-aos="flip-left"
//               data-aos-easing="ease-out-cubic"
//               data-aos-duration="1000"
//             >
//               <div className="service__head">
//                 <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Cloud Computing Basics</h3>
//               </div>

//               <ul className="service__list " style={{ fontFamily: 'Instagram Sans Regular' }}>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Introduction to cloud computing and its benefits</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Overview of cloud service models: IaaS, PaaS, SaaS</p>
//                 </li>
//                 <li  className="pt-3 pb-3">
//                   <BiCheck className="service__list-icon" />
//                   <p>
//                   Understanding cloud deployment models: public, private, hybrid, and community clouds
//                   </p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Key cloud computing providers and their offerings</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Security and compliance considerations in cloud computing</p>
//                 </li>
//               </ul>
//             </article>
//             {/* END OF Cloud Computing Basics */}
//             <article
//               className="service"
//               data-aos="flip-left"
//               data-aos-easing="ease-out-cubic"
//               data-aos-duration="1000"
//             >
//               <div className="service__head">
//                 <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Cloud Infrastructure</h3>
//               </div>

//               <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Virtualization and containerization technologies</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Cloud storage solutions and data management</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Networking in the cloud: virtual networks, VPNs, and SDN</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Scalability and elasticity in cloud infrastructure</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Monitoring and management tools for cloud environments</p>
//                 </li>
//               </ul>
//             </article>
//             {/* END OF Cloud Infrastructure */}
//             <article
//               className="service"
//               data-aos="flip-left"
//               data-aos-easing="ease-out-cubic"
//               data-aos-duration="1000"
//             >
//               <div className="service__head">
//                 <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Cloud Services</h3>
//               </div>

//               <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Overview of cloud services: computing, storage, databases, networking, and more</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Building and deploying applications on cloud platforms</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Serverless computing and microservices architecture</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Integration and APIs in cloud-based applications</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Cloud-native development and DevOps practices</p>
//                 </li>
//               </ul>
//             </article>
//             {/* END OF Cloud Services */}
//           </div>
//         </section>
//       </div>
//     </div>
//   );
// };

// export default Service;


import "./service.css";
import { BiCheck } from "react-icons/bi";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const Service = () => {
  const serviceContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    padding: "20px",
  };

  const hrStyle = {
    width: "100%",
    margin: "20px 0px",
  };

  return (
    <div style={serviceContainerStyle} id="learn">
      <div data-aos="fade-up">
        <h1 style={{ fontFamily: 'Instagram Sans Bold' }} className="md:text-5xl text-2xl">Learn with Us</h1>
      </div>
      <div style={hrStyle}>
        <hr />
      </div>
      <div>
        <section id="services">
          <div className="container services__container md:mr-40">
            <article
              className="service"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <div className="service__head">
                <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Stock Market Fundamentals</h3>
              </div>

              <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Introduction to stock markets and how they work</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Understanding stocks, bonds, and other financial instruments</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Key concepts: market indices, IPOs, and market capitalization</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Types of stock market orders: market, limit, and stop-loss orders</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Risks and rewards of stock market investing</p>
                </li>
              </ul>
            </article>
            {/* END OF Stock Market Fundamentals */}
            <article
              className="service"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <div className="service__head">
                <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Technical Analysis</h3>
              </div>

              <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Introduction to chart patterns and technical indicators</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Understanding moving averages, RSI, and MACD</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Identifying trends, support, and resistance levels</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Using candlestick patterns for market predictions</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Developing a trading strategy based on technical analysis</p>
                </li>
              </ul>
            </article>
            {/* END OF Technical Analysis */}
            <article
              className="service"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <div className="service__head">
                <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Fundamental Analysis</h3>
              </div>

              <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Evaluating company financials: balance sheet, income statement, and cash flow</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Understanding key financial ratios: P/E, ROE, and debt-to-equity</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Impact of economic indicators on stock prices</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Analyzing market news and its effect on stock market trends</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Long-term vs. short-term investment strategies</p>
                </li>
              </ul>
            </article>
            {/* END OF Fundamental Analysis */}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Service;
