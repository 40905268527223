import React from 'react'
import { useState } from 'react';
import axios from 'axios';

export default function Contactus() {
  // { fullName, phoneNumber,description }

const [fullName,setfullName]=useState('')
const [phoneNumber,setphoneNumber]=useState('')
const [description,setdescription]=useState('')



async function submit(e){

if(fullName != '' && phoneNumber != ''){
  e.preventDefault();

    try {
      const response = await axios.post("https://codeioxbe.vercel.app/saveData", {fullName,phoneNumber,description});
      console.log('Response:', response.data);
      window.alert("Your message is successfully submitted");
      setphoneNumber('')
      setdescription('')
      setfullName('')
    } catch (error) {
      console.error('Error sending data:', error);
    }
}

}

  return (
    <div>
        <section className="text-gray-600 body-font relative">
  <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
    <div className="lg:w-2/3 md:w-1/2 bg-blue-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
      <iframe width="100%" height="100%" className="absolute inset-0" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.8870188832925!2d77.37530537478568!3d28.603166025680817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce57d5a780cbf%3A0xe46379473642ef2f!2sBlock%20M%2C%20Mamura%2C%20Sector%2066%2C%20Noida%2C%20Uttar%20Pradesh%20201301!5e0!3m2!1sen!2sin!4v1720551109952!5m2!1sen!2sin" style={{filter: "grayscale(1) contrast(1.2) opacity(0.4)"}}></iframe>
      {/* <iframe  width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
      <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
        <div className="lg:w-1/2 px-6">
          <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
          <p className="mt-1">
          Codeiox EDUTECH PRIVATE LIMITED  M- BLOCK,
Mamura,
Sector 66 - Noida,
Uttar Pradesh,
201301
            </p>
        </div>
        <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
          <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
          <a className="text-indigo-500 leading-relaxed">support@codeiox.in</a>
          <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
          <p className="leading-relaxed">+91-8121777057</p>
        </div>
      </div>
    </div>
    <div className="lg:w-1/3 md:w-1/2 rounded p-4 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
      <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Feedback</h2>
      <p className="leading-relaxed mb-5 text-gray-600">Connect with us in case you have any query!</p>
      <div className="relative mb-4">
        <label for="name" className="leading-7 text-sm text-gray-600">Name</label>
        <input type="text" id="name" name="name" value={fullName} onChange={(e)=>setfullName(e.target.value)} className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
      </div>
      <div className="relative mb-4">
        <label for="email" className="leading-7 text-sm text-gray-600">Phone Number</label>
        <input type="email" id="email" name="email" value={phoneNumber} onChange={(e)=>setphoneNumber(e.target.value)} className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
      </div>
      <div className="relative mb-4">
        <label for="message" className="leading-7 text-sm text-gray-600">Message</label>
        <textarea id="message" name="message" value={description} onChange={(e)=>setdescription(e.target.value)} className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
      </div>
      <button className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg" onClick={submit}>Send Message</button>
      <p className="text-xs text-gray-500 mt-3">Codeiox EDUTECH PRIVATE LIMITED</p>
    </div>
  </div>
</section>
    </div>
  )
}
{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3877.1871555684393!2d79.44464187429803!3d13.646376586734512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4d4b0494c34497%3A0x95f3307f1b828f3b!2sSRINIVASA%20TOWERS!5e0!3m2!1sen!2sin!4v1713648693143!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}