import "./service.css";
import { BiCheck } from "react-icons/bi";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const Service = () => {
  const serviceContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    padding: "20px",
  };

  const hrStyle = {
    width: "100%",
    margin: "20px 0px",
  };

  return (
    <div style={serviceContainerStyle} id="learn">
      <div data-aos="fade-up">
        <h1 style={{ fontFamily: 'Instagram Sans Bold' }} className="md:text-5xl text-2xl">Learn with Us</h1>
      </div>
      <div style={hrStyle}>
        <hr />
      </div>
      <div>
        <section id="services">
          <div className="container services__container md:mr-40">
            <article
              className="service"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <div className="service__head">
                <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Introduction to AI</h3>
              </div>

              <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
    <li className="pt-3 pb-3 text-xl">
      <BiCheck className="service__list-icon" />
      <p>Introduction to Artificial Intelligence</p>
    </li>
    <li className="pt-3 pb-3 text-xl">
      <BiCheck className="service__list-icon" />
      <p>Applications of AI in Real-World Scenarios</p>
    </li>
    <li className="pt-3 pb-3 text-xl">
      <BiCheck className="service__list-icon" />
      <p>AI vs Machine Learning vs Deep Learning</p>
    </li>
    <li className="pt-3 pb-3 text-xl">
      <BiCheck className="service__list-icon" />
      <p>Ethics and Bias in Artificial Intelligence</p>
    </li>
    <li className="pt-3 pb-3 text-xl">
      <BiCheck className="service__list-icon" />
      <p>History and Evolution of AI</p>
    </li>
  </ul>
            </article>
            {/* END OF UI/UX */}
            <article
              className="service"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <div className="service__head">
                <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Deep Learning & Neural Networks</h3>
              </div>

              <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
      <li className="pt-3 pb-3 text-xl">
        <BiCheck className="service__list-icon" />
        <p>Fundamentals of Neural Networks</p>
      </li>
      <li className="pt-3 pb-3 text-xl">
        <BiCheck className="service__list-icon" />
        <p>Deep Learning Architectures</p>
      </li>
      <li className="pt-3 pb-3 text-xl">
        <BiCheck className="service__list-icon" />
        <p>Training and Evaluating AI Models</p>
      </li>
      <li className="pt-3 pb-3 text-xl">
        <BiCheck className="service__list-icon" />
        <p>Understanding Hyperparameters</p>
      </li>
      <li className="pt-3 pb-3 text-xl">
        <BiCheck className="service__list-icon" />
        <p>Optimization Techniques for Neural Networks</p>
      </li>
    </ul>
            </article>
            {/* END OF CONTENT CREATION */}
            <article
              className="service"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <div className="service__head">
                <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Applications of AI</h3>
              </div>

              <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
      <li className="pt-3 pb-3 text-xl">
        <BiCheck className="service__list-icon" />
        <p>Computer Vision Basics</p>
      </li>
      <li className="pt-3 pb-3 text-xl">
        <BiCheck className="service__list-icon" />
        <p>Introduction to Natural Language Processing</p>
      </li>
      <li className="pt-3 pb-3 text-xl">
        <BiCheck className="service__list-icon" />
        <p>Deploying AI Models</p>
      </li>
      <li className="pt-3 pb-3 text-xl">
        <BiCheck className="service__list-icon" />
        <p>Speech Recognition Systems</p>
      </li>
      <li className="pt-3 pb-3 text-xl">
        <BiCheck className="service__list-icon" />
        <p>AI in Robotics and Automation</p>
      </li>
    </ul>
            </article>
            {/* END OF WEB DEVELOPMENT */}
            
          </div>
        </section>
      </div>
    </div>

//  <article
//   className="service"
//   data-aos="flip-left"
//   data-aos-easing="ease-out-cubic"
//   data-aos-duration="1000"
// >
//   <div className="service__head">
//     <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Artificial Intelligence</h3>
//   </div>

//   <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
//     <li className="pt-3 pb-3 text-xl">
//       <BiCheck className="service__list-icon" />
//       <p>Introduction to Artificial Intelligence</p>
//     </li>
//     <li className="pt-3 pb-3 text-xl">
//       <BiCheck className="service__list-icon" />
//       <p>Applications of AI in Real-World Scenarios</p>
//     </li>
//     <li className="pt-3 pb-3 text-xl">
//       <BiCheck className="service__list-icon" />
//       <p>AI vs Machine Learning vs Deep Learning</p>
//     </li>
//     <li className="pt-3 pb-3 text-xl">
//       <BiCheck className="service__list-icon" />
//       <p>Ethics and Bias in Artificial Intelligence</p>
//     </li>
//     <li className="pt-3 pb-3 text-xl">
//       <BiCheck className="service__list-icon" />
//       <p>History and Evolution of AI</p>
//     </li>
//   </ul>

//   <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
//     <li className="pt-3 pb-3 text-xl">
//       <BiCheck className="service__list-icon" />
//       <p>Fundamentals of Neural Networks</p>
//     </li>
//     <li className="pt-3 pb-3 text-xl">
//       <BiCheck className="service__list-icon" />
//       <p>Deep Learning Architectures</p>
//     </li>
//     <li className="pt-3 pb-3 text-xl">
//       <BiCheck className="service__list-icon" />
//       <p>Training and Evaluating AI Models</p>
//     </li>
//     <li className="pt-3 pb-3 text-xl">
//       <BiCheck className="service__list-icon" />
//       <p>Understanding Hyperparameters</p>
//     </li>
//     <li className="pt-3 pb-3 text-xl">
//       <BiCheck className="service__list-icon" />
//       <p>Optimization Techniques for Neural Networks</p>
//     </li>
//   </ul>

//   <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
//     <li className="pt-3 pb-3 text-xl">
//       <BiCheck className="service__list-icon" />
//       <p>Computer Vision Basics</p>
//     </li>
//     <li className="pt-3 pb-3 text-xl">
//       <BiCheck className="service__list-icon" />
//       <p>Introduction to Natural Language Processing</p>
//     </li>
//     <li className="pt-3 pb-3 text-xl">
//       <BiCheck className="service__list-icon" />
//       <p>Deploying AI Models</p>
//     </li>
//     <li className="pt-3 pb-3 text-xl">
//       <BiCheck className="service__list-icon" />
//       <p>Speech Recognition Systems</p>
//     </li>
//     <li className="pt-3 pb-3 text-xl">
//       <BiCheck className="service__list-icon" />
//       <p>AI in Robotics and Automation</p>
//     </li>
//   </ul>
// </article> */}

  );
};

export default Service;
