import React from 'react';
import img from './internet.png'
import img2 from './engagement.png'
import img3 from './data.png'
import img4 from './brain.png'
import python from './Python.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCalendarDays, faGraduationCap } from '@fortawesome/free-solid-svg-icons';

export default function Offercourse() {
  const programData = [
    {
      'imgSrc': 'https://corizo.in/wp-content/uploads/2024/09/tab-prod-img01.jpg',
      'topic': 'Web Development',
      'description': 'We’ll teach you to use and implement the fundamental building blocks of the web to ensure you are skilled enough to develop your own website.',
      'time': '2 Months',
      'mentees': '20k+ Mentees',
      'link': '/webdev'
    },
    {
      'imgSrc': python,
      'topic': 'Python Programming',
      'description': "Python programming is known for its simplicity and versatility. It's used for various purposes, from web development to machine learning.",
      'time': '2 Months',
      'mentees': '20k+ Mentees',
      'link': '/python'
    },
    {
      'imgSrc': 'https://corizo.in/wp-content/uploads/2024/09/datascience.jpg',
      'topic': 'Data Science',
      'description': 'This Data Science program teaches you to use industry tools, analyze data, identify trends, and support business decisions with facts.',
      'time': '2 Months',
      'mentees': '20k+ Mentees',
      'link': '/dscience'
    },
    {
      'imgSrc': 'https://corizo.in/wp-content/uploads/2024/09/Artificial.jpg',
      'topic': 'Artificial Intelligence',
      'description': 'Artificial Intelligence (AI) explores intelligent systems, focusing on machine learning, reasoning, and autonomous actions across various scientific fields.',
      'time': '2 Months',
      'mentees': '20k+ Mentees',
      'link': '/ml'
    },
  ]

  return (

    <section className='bg-violet-950 p-4 py-16 mt-8'>
      <h1 className='text-xl font-bold text-white text-center mb-6'>Our Programs</h1>
      <h1 className="text-white mx-auto font-medium sm:w-1/2 text-center title-font sm:text-5xl text-3xl mb-2 sm:mb-0">WHAT KIND OF PROGRAMS WE ARE OFFERING</h1>
      <ul className='flex flex-wrap justify-around mt-16 '>
        {(programData.length>0) ? (
          programData.map((data, index) =>(
            <li key={index} className="h-fit w-fit max-w-[313px] mb-10">
              <div className="relative">
                <img width="313" height="209" src={data.imgSrc} className="w-full max-w-[313px] rounded-t-lg" alt="card-img" decoding="async" fetchpriority="high" />									
                {/* <img src="/images/tab-prod-img01.jpg" alt=""/> */}
                <h4 className="text-2xl font-bold text-gray-800 text-white absolute bottom-2 left-2">{data.topic}</h4>
              </div>

              <div className="flex flex-col gap-3 p-2 bg-white rounded-b-lg">  
                <img src="https://corizo.in/wp-content/themes/techglobiz/images/tab-star-icn.jpg" alt=""/>
                <p>{data.description}</p>

                <div className="flex gap-2">
                  <a href="#" className="w-fit h-fit py-1 px-2 bg-slate-200 rounded-md"> <FontAwesomeIcon icon={faCalendarDays} className='mr-2'/>{data.time}</a>
                  <a href="#" className="w-fit h-fit py-1 px-2 bg-slate-200 rounded-md"> <FontAwesomeIcon icon={faGraduationCap} className='mr-2'/>{data.mentees}</a>
                </div>
                <div className='h-[1px] w-full px-2 bg-slate-200 mt-2'></div>
                <div className="text-[#8A01EF] font-bold">
                  <a href={data.link} className='group'>Know More <FontAwesomeIcon className="transform transition-transform duration-500 group-hover:rotate-0 -rotate-45 ml-1" icon={faArrowRight}/></a>
                </div>
              </div>
            </li>
          ))
        ) : (
          <div>No any program data found</div>
        )}
        
      </ul>        
    </section>





    // <div className='mb-5'>
    //   <section className="text-gray-600 body-font">
    //     <div className="container px-5 py-5 mx-auto">
    //       <div className="flex flex-col">
    //         <p className='text-center mx-auto text-blue-600 font-bold'>OUR PROGRAMS</p>
    //         <div className="flex flex-wrap sm:flex-row flex-col pt-4 pb-10  mb-5">
    //           <h1 className="text-gray-900 mx-auto font-medium sm:w-1/2 text-center title-font sm:text-5xl text-3xl mb-2 sm:mb-0">WHAT KIND OF PROGRAMS WE ARE OFFERING</h1>
    //         </div>
    //       </div>
    //       <div className="flex flex-wrap justify-center ">
    //         <div className="px-10 py-10 w-full sm:w-1/2 lg:w-1/4 sm:mb-0 mb-6 bg-slate-100 border-2 shadow-md hover:bg-blue-600 hover:text-white rounded-md">
    //           <div className="rounded-full w-20 h-20 bg-slate-300 overflow-hidden flex justify-center items-center">
    //             <img className='object-cover w-14 h-14' src={img} alt="Image"/>
    //           </div>
    //           <h2 className="text-xl title-font text-gray-900 font-semibold mt-5">Web Development</h2>
    //           <p className="text-base leading-relaxed mt-2">We’ll teach you to use and implement the fundamental building blocks of the web to ensure you are skilled enough to develop your own website</p>
    //           <button className='bg-slate-300 rounded-md p-4 my-2'>
    //             <a href='/webdev' className="text-indigo-500">Learn More</a>
    //           </button>
    //         </div>
    //         <div className="px-10 py-10 w-full sm:w-1/2 lg:w-1/4 sm:mb-0 mb-6 bg-slate-100 border-2 shadow-md hover:bg-blue-600 hover:text-white rounded-md">
    //           <div className="rounded-full w-20 h-20 bg-slate-300 overflow-hidden flex justify-center items-center">
    //             <img className='object-cover w-14 h-14' src={img2} alt="Image"/>
    //           </div>
    //           <h2 className="text-xl title-font text-gray-900 font-semibold mt-5">Python Programming</h2>
    //           <p className="text-base leading-relaxed mt-2">Python programming involves writing code using the Python language, known for its simplicity and versatility. It's used for various purposes, from web development to machine learning, thanks to its extensive library ecosystem.</p>
    //           <button className='bg-slate-300 rounded-md p-4 my-2'>
    //             <a href='/python' className="text-indigo-500">Learn More</a>
    //           </button>
    //         </div>
    //         <div className="hidden lg:block px-10 md:py-10 lg:w-1/4 bg-slate-100 border-2 shadow-md sm:mb-0 mb-6 rounded-md hover:bg-blue-600 hover:text-white">
    //           <div className="rounded-full w-20 h-20 bg-slate-300 overflow-hidden flex justify-center items-center">
    //             <img className='object-cover w-14 h-14' src={img3} alt="Image"/>
    //           </div>
    //           <h2 className="text-xl font-semibold title-font text-black mt-5">Data Science</h2>
    //           <p className="text-base leading-relaxed mt-2">In this program you’ll be taught to use all the tools required and identify trends, patterns and gain insights from raw data as required in the industry. You will leave this program having learnt how to validate and support big business decisions with facts and figures.</p>
    //           <button className='bg-slate-300 rounded-md p-4 my-2'>
    //             <a href='/dscience' className="text-indigo-500">Learn More</a>
    //           </button>
    //         </div>
    //         <div className="hidden lg:block px-10 md:py-10 lg:w-1/4 bg-slate-100 border-2 shadow-md sm:mb-0 mb-6 rounded-md hover:bg-blue-600 hover:text-white">
    //           <div className="rounded-full w-20 h-20 bg-slate-300 overflow-hidden flex justify-center items-center">
    //             <img className='object-cover w-14 h-14' src={img4} alt="Image"/>
    //           </div>
    //           <h2 className="text-xl font-semibold title-font text-black mt-5">Artificial Intelligence</h2>
    //           <p className="text-base leading-relaxed mt-2">
    //           Artificial Intelligence (AI) is the study of intelligent agents and their behaviors, spanning machines and software systems. 
    //           It explores the creation of systems capable of perceiving, reasoning, learning, and acting autonomously. 
    //           AI delves into conscious and unconscious computational processes, crossing computer science, cognitive science, and engineering.
    //           </p>
    //           <button className='bg-slate-300 rounded-md p-4 my-2'>
    //             <a href='/ml' className="text-indigo-500">Learn More</a>
    //           </button>
    //         </div>
    //       </div>
    //       <div className="lg:hidden">
    //         {/* These divs are only shown on small screens */}
    //         <div className="px-10 py-10 w-full  bg-slate-100 border-2 shadow-md sm:mb-0 mb-6 rounded-md hover:bg-blue-600 hover:text-white">
    //           <div className="rounded-full w-20 h-20 bg-slate-300 overflow-hidden flex justify-center items-center ">
    //             <img className='object-cover w-14 h-14' src={img3} alt="Image"/>
    //           </div>
    //           <h2 className="text-xl font-semibold title-font text-black mt-5">Data Science</h2>
    //           <p className="text-base leading-relaxed mt-2">In this program you’ll be taught to use all the tools required and identify trends, patterns and gain insights from raw data as required in the industry. You will leave this program having learnt how to validate and support big business decisions with facts and figures.</p>
    //           <button className='bg-slate-300 rounded-md p-4 my-2'>
    //             <a className="text-indigo-500">Learn More</a>
    //           </button>
    //         </div>
    //         <div className="px-10 py-10 w-full bg-slate-100 border-2 shadow-md sm:mb-0 mb-6 rounded-md hover:bg-blue-600 hover:text-white">
    //           <div className="rounded-full w-20 h-20 bg-slate-300 overflow-hidden flex justify-center items-center">
    //             <img className='object-cover w-14 h-14' src={img4} alt="Image"/>
    //           </div>
    //           <h2 className="text-xl font-semibold title-font text-black mt-5">Psychology</h2>
    //           <p className="text-base leading-relaxed mt-2">Psychology is the study of mind and behavior in humans and non-humans. Psychology includes the study of conscious and unconscious phenomena, including feelings and thoughts. It is an academic discipline of immense scope, crossing the boundaries between the natural and social sciences.</p>
    //           <button className='bg-slate-300 rounded-md p-4 my-2'>
    //             <a className="text-indigo-500">Learn More</a>
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    // </div>
  );
}

