// import React from "react";
// // import "./footer.css";
// import { FaFacebookF } from "react-icons/fa";
// import { FiInstagram } from "react-icons/fi";
// import { IoLogoTwitter } from "react-icons/io";
// import {FaYoutube} from "react-icons/fa";
// import logo2 from "../img/logo2.png";

// import AOS from "aos";
// import "aos/dist/aos.css";

// AOS.init();

// function Footer() {
//   return (
//     <footer className="text-gray-600 body-font bg-blue-600">
//     <div className="container px-5 py-24 mx-auto">
//       <div className="flex flex-wrap md:text-left text-center order-first">
//         <div className="lg:w-1/4 md:w-1/2 w-full px-4">
//           <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
//           <nav className="list-none mb-10">
//             <li>
//               <a className="text-gray-600 hover:text-gray-800">First Link</a>
//             </li>
//             <li>
//               <a className="text-gray-600 hover:text-gray-800">Second Link</a>
//             </li>
//             <li>
//               <a className="text-gray-600 hover:text-gray-800">Third Link</a>
//             </li>
//             <li>
//               <a className="text-gray-600 hover:text-gray-800">Fourth Link</a>
//             </li>
//           </nav>
//         </div>
//         <div className="lg:w-1/4 md:w-1/2 w-full px-4">
//           <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
//           <nav className="list-none mb-10">
//             <li>
//               <a className="text-gray-600 hover:text-gray-800">First Link</a>
//             </li>
//             <li>
//               <a className="text-gray-600 hover:text-gray-800">Second Link</a>
//             </li>
//             <li>
//               <a className="text-gray-600 hover:text-gray-800">Third Link</a>
//             </li>
//             <li>
//               <a className="text-gray-600 hover:text-gray-800">Fourth Link</a>
//             </li>
//           </nav>
//         </div>
//         <div className="lg:w-1/4 md:w-1/2 w-full px-4">
//           <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
//           <nav className="list-none mb-10">
//             <li>
//               <a className="text-gray-600 hover:text-gray-800">First Link</a>
//             </li>
//             <li>
//               <a className="text-gray-600 hover:text-gray-800">Second Link</a>
//             </li>
//             <li>
//               <a className="text-gray-600 hover:text-gray-800">Third Link</a>
//             </li>
//             <li>
//               <a className="text-gray-600 hover:text-gray-800">Fourth Link</a>
//             </li>
//           </nav>
//         </div>
//         <div className="lg:w-1/4 md:w-1/2 w-full px-4">
//           <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">SUBSCRIBE</h2>
//           <div className="flex xl:flex-nowrap md:flex-nowrap lg:flex-wrap flex-wrap justify-center items-end md:justify-start">
//             <div className="relative w-40 sm:w-auto xl:mr-4 lg:mr-0 sm:mr-4 mr-2">
//               <label for="footer-field" className="leading-7 text-sm text-gray-600">Placeholder</label>
//               <input type="text" id="footer-field" name="footer-field" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
//             </div>
//             <button className="lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded">Button</button>
//           </div>
//           <p className="text-gray-500 text-sm mt-2 md:text-left text-center">Bitters chicharrones fanny pack
//             <br className="lg:block hidden"/>waistcoat green juice
//           </p>
//         </div>
//       </div>
//     </div>
//     <div className="bg-gray-100">
//       <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
//         <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
//           <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">
//             <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
//           </svg>
//           <span className="ml-3 text-xl">Tailblocks</span>
//         </a>
//         <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">© 2020 Tailblocks —
//           <a href="https://twitter.com/knyttneve" rel="noopener noreferrer" className="text-gray-600 ml-1" target="_blank">@knyttneve</a>
//         </p>
//         <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
//           <a className="text-gray-500">
//             <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
//               <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
//             </svg>
//           </a>
//           <a className="ml-3 text-gray-500">
//             <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
//               <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
//             </svg>
//           </a>
//           <a className="ml-3 text-gray-500">
//             <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
//               <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
//               <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
//             </svg>
//           </a>
//           <a className="ml-3 text-gray-500">
//             <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" className="w-5 h-5" viewBox="0 0 24 24">
//               <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
//               <circle cx="4" cy="4" r="2" stroke="none"></circle>
//             </svg>
//           </a>
//         </span>
//       </div>
//     </div>
//   </footer>
//   );
// }

// export default Footer;


import React from 'react'
import m from './mail.png'
import logo from './logom.png'
import isoLogo from '../img/iso-logo.jpg'

export default function Footer() {
  return (
    <div>
      <footer className="text-white body-font bg-violet-950" >
  <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-wrap md:text-left text-center order-first">
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font font-bold md:text-lg text-white tracking-widest text-sm mb-3">About Us</h2>
        <p className='text-gray-200'>
        Codeiox is an edtech platform dedicated to assisting students with internships, professional training programs, career guidance, and mentorship. Our primary goal is to narrow the divide between formal education and the constantly evolving demands of the industry.          </p>
      </div>
      <div className="lg:w-1/4 md:w-1/2 w-full px-4 my-2">
        <h2 className="title-font md:text-lg mx-auto  font-bold  tracking-widest text-sm mb-3 text-white">Link</h2>
        <nav className="list-disc mb-10">
          <li>
            <a className=" hover:text-gray-800 text-white" href='/'>Home</a>
          </li>
          <li>
            <a className=" hover:text-gray-800 text-white" href='/us'>About Us</a>
          </li>
          <li>
            <a className=" hover:text-gray-800 text-white"href='/contactus'>Contact Us</a>
          </li>
         
        </nav>
      </div>
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
      <h2 className="title-font md:text-lg font-bold  tracking-widest text-sm mb-3 text-white">Our Programs</h2>
       <nav className="list-disc mb-10">
        <li>
<a className=" hover:text-gray-800 text-white" href='/policy'>
 Privacy Policy</a>
          </li>
        <li>
          <a className=" hover:text-gray-800 text-white" href='/term'>

 Terms & Conditions
 </a>
            </li>
            <li>
               <a className=" hover:text-gray-800 text-white" href='/cancellation'>
   Payment and Refund</a>
            </li>
            {/* <li>
               <a className=" hover:text-gray-800 text-white">
               FAQ</a>
            </li> */}
         
                  </nav>
                         </div>
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font md:text-lg font-bold  tracking-widest text-sm mb-3 text-white">Contact Us</h2>
        <nav className="list-disc mb-10">
          <li>
             {/* <img src={m} className='w-10 h-10 rounded-full'/> */}
            <a className=" hover:text-gray-800 text-white">
            support@codeiox.in</a>
          </li>
          <li>
            <a className=" hover:text-gray-800 text-white">

            (+91) – 812-177-7057</a>
          </li>
          <li>
            <a className=" hover:text-gray-800 text-white">
            Codeiox EDUTECH PRIVATE LIMITED <br/>Kashi Math, Tirupati, Tirupati (Urban), Chittoor, Andhra Pradesh, 517507</a>
          </li>
          
         
        </nav>
       
      </div>
    </div>
  </div>
  <div className="bg-gray-100">
    <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
      <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
        <img src={logo} className='rounded full h-20 w-20'/>
        <span className="ml-3 text-xl">Codeiox</span>
      </a>
      <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">© 2024 Codeiox 
      </p>
      <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
        {/* <a className="text-gray-500">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
          </svg>
        </a>
        <a className="ml-3 text-gray-500">
          <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
          </svg>
        </a> */}
        <a href='https://www.instagram.com/codeiox/' className="ml-3 text-gray-500">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
          </svg>
        </a>
        <a href='https://www.linkedin.com/company/codeiox' className="ml-3 text-gray-500">
          <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" className="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
        </a>
      </span>
    </div>
  </div>

  {/* <div className='h-1 w-full bg-slate-300'></div> */}
  <div className='py-2 flex max-lg:flex-col  justify-center items-center gap-4 font-semibold'>
  <p>© 100% safe and secure payment with</p>
  <ul className='flex justify-center items-center gap-4'>
    <li>
      <img src="https://corizo.in/wp-content/themes/techglobiz/images/ftr-razorpay.png" alt=""/>
    </li>
    <li>
      <img src={isoLogo} alt="" className='h-8 rounded-lg'/>
    </li>
    <li>
      <img src="https://corizo.in/wp-content/uploads/2024/10/goole.png" alt=""/>
    </li>
    <li>
      <img src="https://corizo.in/wp-content/themes/techglobiz/images/startup-logo.png" alt=""/>
    </li>
  </ul>
  </div>
</footer>
    </div>
  )
}
