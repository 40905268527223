import React from 'react'

export default function Policy() {
  return (
<div className="flex items-center justify-center min-h-screen bg-gray-200">
  <div className="bg-white shadow-lg rounded-lg overflow-hidden font-sans leading-normal tracking-normal max-w-4xl mx-auto">
    <div className="container flex flex-wrap py-6">
      <div className="w-full flex flex-col items-center justify-center p-6">
        <h1 className="text-4xl text-center mb-4 font-bold text-indigo-700">Privacy Policy</h1>

        <p className="text-lg text-gray-800 mb-6">
          This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our website, codeiox.in, and the services provided by Codeiox. By accessing or using our platform, you consent to the practices described in this Privacy Policy.
        </p>

        <div className="w-full">
          <h2 className="text-2xl font-semibold text-indigo-600 mb-4">1. Information We Collect</h2>
          <p className="text-lg text-gray-800 mb-4">
            <strong>a. Personal Information:</strong> When you register for an account or use our services, we may collect personal information, including but not limited to your name, email address, contact details, and payment information.
          </p>
          <p className="text-lg text-gray-800 mb-4">
            <strong>b. Usage Information:</strong> We may collect information about your use of Codeiox, such as the pages you visit, the features you use, and the actions you take.
          </p>
          <p className="text-lg text-gray-800 mb-4">
            <strong>c. Device Information:</strong> We may collect information about the device you use to access Codeiox, including the device type, operating system, and browser type.
          </p>
        </div>

        <div className="w-full">
          <h2 className="text-2xl font-semibold text-indigo-600 mb-4">2. How We Use Your Information</h2>
          <p className="text-lg text-gray-800 mb-4">
            <strong>a. Providing Services:</strong> We use your information to provide and improve our services, customize your experience, and respond to your inquiries.
          </p>
          <p className="text-lg text-gray-800 mb-4">
            <strong>b. Communication:</strong> We may use your email address to send you important updates, newsletters, and promotional materials. You can opt out of promotional emails at any time.
          </p>
          <p className="text-lg text-gray-800 mb-4">
            <strong>c. Analytics:</strong> We use analytics tools to analyze usage patterns and improve the functionality and content of Codeiox.
          </p>
        </div>

        <div className="w-full">
          <h2 className="text-2xl font-semibold text-indigo-600 mb-4">3. Cookies and Similar Technologies</h2>
          <p className="text-lg text-gray-800 mb-4">
            We use cookies and similar technologies to enhance your experience on Codeiox. You can manage your cookie preferences through your browser settings.
          </p>
        </div>

        <div className="w-full">
          <h2 className="text-2xl font-semibold text-indigo-600 mb-4">4. Information Sharing</h2>
          <p className="text-lg text-gray-800 mb-4">
            <strong>a. Third-Party Service Providers:</strong> We may share your information with third-party service providers who help us deliver our services, process payments, and perform other functions.
          </p>
          <p className="text-lg text-gray-800 mb-4">
            <strong>b. Legal Compliance:</strong> We may disclose your information if required by law or in response to valid legal requests.
          </p>
        </div>

        <div className="w-full">
          <h2 className="text-2xl font-semibold text-indigo-600 mb-4">5. Security</h2>
          <p className="text-lg text-gray-800 mb-4">
            We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no data transmission or storage system is completely secure, and we cannot guarantee the security of your information.
          </p>
        </div>

        <div className="w-full">
          <h2 className="text-2xl font-semibold text-indigo-600 mb-4">6. Children's Privacy</h2>
          <p className="text-lg text-gray-800 mb-4">
            Codeiox is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have collected information from a child under 13, please contact us immediately.
          </p>
        </div>

        <div className="w-full">
          <h2 className="text-2xl font-semibold text-indigo-600 mb-4">7. Changes to the Privacy Policy</h2>
          <p className="text-lg text-gray-800 mb-4">
            We reserve the right to update this Privacy Policy at any time. The updated policy will be effective upon posting on our website. We encourage you to review this Privacy Policy periodically.
          </p>
        </div>

        <div className="w-full">
          <h2 className="text-2xl font-semibold text-indigo-600 mb-4">8. Contact Us</h2>
          <p className="text-lg text-gray-800 mb-6">
            If you have questions or concerns about this Privacy Policy, please contact us at '8840356074' or support@codeiox.in
          </p>
        </div>

        <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
      </div>
    </div>
  </div>
</div>


   
  )
}
