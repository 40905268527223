// import React, { useEffect, useState } from "react";
// import "./body.css";
// import { FaAward } from "react-icons/fa";
// import { FiUsers } from "react-icons/fi";
// import { VscFolderLibrary } from "react-icons/vsc";
// import axios from 'axios';
// import AOS from "aos";
// import "aos/dist/aos.css";

// import herobgsmscreen from '../img/herobgsmscreen.png'


// AOS.init({
//   duration: 1000,
// });

// const Hero = () => {
//   const [toggleText, setToggleText] = useState("Deserve");
//   const [toggleText1, setToggleText1] = useState("Bring!");
//   const colors = ["red", "blue", "orange"];
//   const [introInstance, setIntroInstance] = useState(null);


//   function changeBackgroundColor() {
//     const button = document.querySelector(".learn-more-btn");
//     const currentColor = button.style.backgroundColor;
//     const currentIndex = colors.indexOf(currentColor);
    
//     const nextIndex = (currentIndex + 1) % colors.length;
  
//     button.style.backgroundColor = colors[nextIndex];
//   }

// const [email, setEmail] = useState("");
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const handleSubmit = async(e) => {
//     e.preventDefault();

//     console.log("Submitted email:", email);
//     setEmail("");
//   let val={
//     "email":email,
//   }
//     console.log(val);
//     // Add logic here to send the data to your backend or perform other actions
//     try{
//       const response = await axios.post("https://listitdown-backend.vercel.app/email/add",val);
//       window.alert("Your email is successfully submitted");
//       console.log('response', response)
     
//      } catch(error){
//         console.error('Error sending data:', error);
//       }


//   };


  
//  const myId = process.env.id
//  console.log('myId', myId)




// useEffect(()=>{

// },[])

//   return (
//    <div className="body-container bg-slate-200">
//       <div className="left-content">
//         <h1 className="mainhead" style={{ fontFamily: 'Instagram Sans Bold' }}>
//           We <span className="sty">{toggleText1}</span> <br />
//           What you <span className="sty">Deserve</span>
//         </h1>
//         <div>
//           <p className="learnmore" style={{ fontFamily: 'Instagram Sans Medium' }}>
//           "Embark on your journey to success with our innovative solutions,<br/> tailored just for you."
//             Your Success,
//             Our Expertise!
//             <br/>
//             <p style={{ fontFamily: 'Instagram Sans Bold', fontSize: '30px' }} className="text-slate-900 my-4">
//               Know more <br/> About Us<br/>
//             </p>
//             <div className="flex flex-col md:flex-row">
//               {/* Button to open modal */}
              
//               <a href="" className="ml-0 md:mr-4 mb-2">
//                 <button 
//                   className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
//                 >
// Know More                </button>
//               </a>
           
//             </div>
//           </p>
//         </div>
//       </div>
//       <div className="review-section" >
//         <div className="reviewBody rounded-md" style={{ fontFamily: 'Instagram Sans Regular', backgroundColor:'rgb(75,75,200)' }}>
//           <h2 className="text-5xl text-white">Our History</h2>
//           <div className="about__cards m-4">
//             <article className="about__card " id="one" data-aos="fade-up">
//               <FaAward className="about__icon mx-auto" />
//               <h5>Community</h5>
//               <small>100K Members</small>
//             </article>
//             <article className="about__card" id="two" data-aos="fade-down">
//               <FiUsers className="about__icon mx-auto" />
//               <h5>Trained Students</h5>
//               <small>1200+</small>
//             </article>
//             <article className="about__card " id="three" data-aos="fade-up">
//               <VscFolderLibrary className="about__icon mx-auto " />
//               <h5>Projects</h5>
//               <small>100+ Completed</small>
//             </article>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Hero;



// import React, { useEffect, useState } from "react";
// import "./body.css";
// import { FaAward } from "react-icons/fa";
// import { FiUsers } from "react-icons/fi";
// import { VscFolderLibrary } from "react-icons/vsc";
// import axios from 'axios';
// import AOS from "aos";
// import "aos/dist/aos.css";
// import herobgsmscreen from '../img/herobgsmscreen.png';

// AOS.init({
//   duration: 1000,
// });

// const Hero = () => {
//   const [toggleText, setToggleText] = useState("Deserve");
//   const [toggleText1, setToggleText1] = useState("Bring!");
//   const colors = ["red", "blue", "orange"];
//   const [introInstance, setIntroInstance] = useState(null);

//   function changeBackgroundColor() {
//     const button = document.querySelector(".learn-more-btn");
//     const currentColor = button.style.backgroundColor;
//     const currentIndex = colors.indexOf(currentColor);
    
//     const nextIndex = (currentIndex + 1) % colors.length;
  
//     button.style.backgroundColor = colors[nextIndex];
//   }

//   const [email, setEmail] = useState("");
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     console.log("Submitted email:", email);
//     setEmail("");
//     let val = { "email": email };
//     console.log(val);
    
//     try {
//       const response = await axios.post("https://listitdown-backend.vercel.app/email/add", val);
//       window.alert("Your email is successfully submitted");
//       console.log('response', response);
//     } catch (error) {
//       console.error('Error sending data:', error);
//     }
//   };

//   const myId = process.env.id;
//   console.log('myId', myId);

//   useEffect(() => {
//     // Initialize AOS
//     AOS.init();
//   }, []);

//   return (
//     <div 
//       className="body-container bg-slate-200 bg-cover bg-center" 
//       style={{ backgroundImage: `url(${herobgsmscreen})` }}
//     >
//       <div className="left-content p-8">
//         <h1 className="mainhead text-4xl md:text-5xl lg:text-6xl" style={{ fontFamily: 'Instagram Sans Bold' }}>
//           We <span className="sty">{toggleText1}</span> <br />
//           What you <span className="sty">Deserve</span>
//         </h1>
//         <div>
//           <p className="learnmore text-lg md:text-xl lg:text-2xl" style={{ fontFamily: 'Instagram Sans Medium' }}>
//             "Embark on your journey to success with our innovative solutions,<br/> tailored just for you."
//             Your Success, Our Expertise!
//             <br/>
//             <p style={{ fontFamily: 'Instagram Sans Bold', fontSize: '30px' }} className="text-slate-900 my-4">
//               Know more <br/> About Us<br/>
//             </p>
//             <div className="flex flex-col md:flex-row">
//               {/* Button to open modal */}
//               <a href="" className="ml-0 md:mr-4 mb-2">
//                 <button 
//                   className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
//                 >
//                   Know More
//                 </button>
//               </a>
//             </div>
//           </p>
//         </div>
//       </div>
//       <div className="review-section p-8">
//         <div className="reviewBody rounded-md bg-blue-700 p-6" style={{ fontFamily: 'Instagram Sans Regular' }}>
//           <h2 className="text-5xl text-white">Our History</h2>
//           <div className="about__cards m-4">
//             <article className="about__card" id="one" data-aos="fade-up">
//               <FaAward className="about__icon mx-auto" />
//               <h5>Community</h5>
//               <small>100K Members</small>
//             </article>
//             <article className="about__card" id="two" data-aos="fade-down">
//               <FiUsers className="about__icon mx-auto" />
//               <h5>Trained Students</h5>
//               <small>1200+</small>
//             </article>
//             <article className="about__card" id="three" data-aos="fade-up">
//               <VscFolderLibrary className="about__icon mx-auto" />
//               <h5>Projects</h5>
//               <small>100+ Completed</small>
//             </article>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Hero;



import React, { useEffect, useState } from "react";
// import "./body.css";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
import axios from 'axios';
import AOS from "aos";
import "aos/dist/aos.css";
import herobgsmscreen from '../img/herobgsmscreen.png';
import heroImg from '../img/hero-photo-collage.png'

// AOS.init({
//   duration: 1000,
// });

const Hero = () => {
  // const [toggleText, setToggleText] = useState("Deserve");
  // const [toggleText1, setToggleText1] = useState("Bring!");
  // const colors = ["blue", "purple"];
  // const [introInstance, setIntroInstance] = useState(null);

  // function changeBackgroundColor() {
  //   const button = document.querySelector(".learn-more-btn");
  //   const currentColor = button.style.backgroundColor;
  //   const currentIndex = colors.indexOf(currentColor);
    
  //   const nextIndex = (currentIndex + 1) % colors.length;
  
  //   button.style.backgroundColor = colors[nextIndex];
  // }

  // const [email, setEmail] = useState("");
  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   console.log("Submitted email:", email);
  //   setEmail("");
  //   let val = { "email": email };
  //   console.log(val);
    
  //   try {
  //     const response = await axios.post("https://listitdown-backend.vercel.app/email/add", val);
  //     window.alert("Your email is successfully submitted");
  //     console.log('response', response);
  //   } catch (error) {
  //     console.error('Error sending data:', error);
  //   }
  // };

  // const myId = process.env.id;
  // console.log('myId', myId);

  // useEffect(() => {
  //   // Initialize AOS
  //   AOS.init();
  // }, []);

  return (

    <section className="h-screen bg-violet-950 flex justify-center items-center text-white max-md:relative overflow-hidden" 
    // style={{backgroundImage: "linear-gradient(90deg, rgba(30,15,54,1) 0%, rgba(63,42,71,1) 100%)"}}
    >
        <div className="absolute z-10 max-md:bg-[#241D54D6] hidden max-md:block h-[107vh] w-screen"></div>
        <div className='flex justify-around items-center gap-4'>
            <div className='flex flex-col gap-8 max-md:z-10  max-md:text-center'>
                <span className='text-6xl font-bold'>We <span className="bg-clip-text text-transparent" style={{backgroundImage: "linear-gradient(#FFF 0%, #B35BF3 100%, #8901ED 100%)"}}>Bring!</span></span>
                <span className='text-6xl font-bold'>What you <span className='bg-clip-text text-transparent' style={{backgroundImage: "linear-gradient(95deg, #6F23A7 25%, #D03C20 100%)"}}> Deserve</span> </span>
                {/* <span className='text-6xl font-bold'>with Expert-led</span> */}
                {/* <span className='text-6xl font-bold'>Programs</span> */}
                <span className='text-xl font-medium max-md:hidden'>
                    <p >"Embark on your journey to success with our innovative solutions,</p>
                    <p>tailored just for you." Your Success, Our Expertise!</p>
                </span>
                <span className='text-xl font-medium hidden max-md:block'>
                    "Embark on your journey to success with our innovative solutions, tailored just for you." Your Success, Our Expertise!
                </span>
                <a href='https://docs.google.com/forms/d/e/1FAIpQLSdfxSaPXszdUzv_F9ErNnToH34ngE6NZGN9GJpUWsvOQXKERA/viewform' className='w-fit h-fit py-4 px-10 text-xl font-medium rounded-lg left-0 bg-[#8A01EF] hover:bg-[#7401C8] max-md:mx-auto' >Get in Touch</a>
            </div>
            <img src={heroImg} alt="hero-img" className='w-[40%] rounded-xl max-md:absolute max-md:z-0  max-md:w-full max-md:scale-150 ' />
        </div>
    </section>



    // <div className="body-container bg-slate-200 relative">
    //   <div className="bg-cover bg-center bg-blue-700 md:hidden absolute inset-0 bg-opacity-50" 
    //        style={{ backgroundImage: `url(${herobgsmscreen})`, filter: 'blur(1px)' }}></div>
    //   <div className="left-content p-8 relative  bg-slate-200 rounded-lg">
    //     <h1 className="mainhead text-4xl md:text-5xl lg:text-6xl" style={{ fontFamily: 'Instagram Sans Bold' }}>
    //       We <span className="text-blue-500">{toggleText1}</span> <br />
    //       What you <span className="text-purple-500">Deserve</span>
    //     </h1>
    //     <div>
    //       <p className="learnmore text-lg md:text-xl lg:text-2xl" style={{ fontFamily: 'Instagram Sans Medium' }}>
    //         "Embark on your journey to success with our innovative solutions,<br/> tailored just for you."
    //         Your Success, Our Expertise!
    //         <br/>
    //         <p style={{ fontFamily: 'Instagram Sans Bold', fontSize: '30px' }} className="text-slate-900 my-4">
    //           Know more <br/> About Us<br/>
    //         </p>
    //         <div className="flex flex-col md:flex-row">
    //           <a href="https://forms.gle/aZqzdEJn5b92A8Rp7" className="ml-0 md:mr-4 mb-2">
    //             <button 
    //               className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    //             >
    //              Get in Touch
    //             </button>
    //           </a>
    //         </div>
    //       </p>
    //     </div>
    //   </div>
    //   <div className="review-section p-8 relative z-10">
    //     <div className="reviewBody rounded-md bg-blue-700 p-6" style={{ fontFamily: 'Instagram Sans Regular' }}>
    //       <h2 className="text-5xl text-white">Our History</h2>
    //       <div className="about__cards m-4">
    //         <article className="about__card" id="one" data-aos="fade-up">
    //           <FaAward className="about__icon mx-auto" />
    //           <h5>Community</h5>
    //           <small>100K Members</small>
    //         </article>
    //         <article className="about__card" id="two" data-aos="fade-down">
    //           <FiUsers className="about__icon mx-auto" />
    //           <h5>Trained Students</h5>
    //           <small>1200+</small>
    //         </article>
    //         <article className="about__card" id="three" data-aos="fade-up">
    //           <VscFolderLibrary className="about__icon mx-auto" />
    //           <h5>Projects</h5>
    //           <small>100+ Completed</small>
    //         </article>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Hero;
