import React, { useState } from "react";
import bgimg from "./bgimg1.png";

export default function Ttu() {
  const sectionStyle = {
    backgroundImage: `url(${bgimg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showAnswer, setShowAnswer] = useState(null);

  const handleHover = (index) => {
    setHoveredIndex(index);
    // Set a slight delay for showing the answer
    setTimeout(() => {
      setShowAnswer(index);
    }, 30); // Delay of 150ms
  };

  const handleHoverLeave = () => {
    setHoveredIndex(null);
    setShowAnswer(null);
  };

  return (
    <section className="text-gray-600 body-font mb-4" style={sectionStyle}>
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center rounded-md">
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0"></div>
        <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex h-2/3 flex-col md:items-start md:text-left items-center text-center">
          <div>
            <section className="mb-5 md:mb-0 bg-black text-white rounded-md">
              <div className="container px-6 py-5 mx-auto ">
                <h1 className="text-2xl font-semibold text-left text-white lg:text-4xl">
                  Frequently asked questions?
                </h1>

                <div className="mt-12 space-y-8 ">
                  {[
                    {
                      question: `01. What are the available modes of payment?`,
                      answer: `You can make the payment using any one of these modes: UPI, debit/credit card, Netbanking/Mobile banking.`,
                    },
                    {
                      question: "02. Who will I be mentored by?",
                      answer: `You are mentored by industry experts who have been/are working in the industry for a decent time and have a strong grasp in their domain of expertise. The process is tailored to provide concept clear training and practical hands-on learning during your upskilling journey.`,
                    },
                    {
                      question: `03. Is there any option of easy monthly installments to avail my program?`,
                      answer:
                        "With No cost EMI as a mode of payment, you can easily avail the same if you qualify a basic eligibility criteria.",
                    },
                  ].map((item, index) => (
                    <div
                      key={index}
                      className="relative border-2 border-gray-100 bg-white rounded-lg dark:border-gray-700"
                      onMouseEnter={() => handleHover(index)}
                      onMouseLeave={handleHoverLeave}
                    >
                      <button className="flex items-center justify-between w-full p-8">
                        <h1
                          className="font-semibold md:text-2xl text-sm text-left text-gray-700"
                          dangerouslySetInnerHTML={{
                            __html: item.question.replace(
                              /<span style={{ color: 'rgb\(20, 143, 128\)' }}>ai<\/span>/g,
                              '<span style="color:rgb(20, 143, 128);">ai</span>'
                            ),
                          }}
                        ></h1>

                        <span className="text-blue-500 bg-gray-300 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`w-6 h-6 transform ${
                              hoveredIndex === index ? "rotate-180" : "rotate-0"
                            }`}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </span>
                      </button>

                      {hoveredIndex === index && (
                        <div
                          className={`absolute rounded-b-lg border-b-2 border-black left-0 right-0 top-full -mt-1 bg-white z-10 shadow-lg p-8 ${
                            showAnswer === index ? "opacity-100" : "opacity-0"
                          } transition-opacity duration-300 ease-in-out`}
                          style={{
                            whiteSpace: "normal",
                            transition: "opacity 0.3s ease-in-out",
                          }}
                        >
                          <hr className="border-gray-700" />
                          <p
                            className="text-xl text-gray-900 text-left"
                            dangerouslySetInnerHTML={{
                              __html: item.answer.replace(
                                /<span style={{ color: 'rgb\(20, 143, 128\)' }}>ai<\/span>/g,
                                '<span style="color:rgb(20, 143, 128);">ai</span>'
                              ),
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
}
