import React from 'react'

export default function Term() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-200 my-4">
    <div className="bg-white shadow-lg rounded-lg overflow-hidden font-sans leading-normal tracking-normal max-w-4xl mx-auto">
      <div className="container flex flex-wrap py-6">
        <div className="w-full flex flex-col items-center justify-center p-6">
          <h1 className="text-4xl text-center mb-4 font-bold text-indigo-700">Terms and Conditions</h1>
  
          <p className="text-lg text-gray-800 mb-6">
            The terms “We,” “Us,” “Our,” and “Company” refer to Institution 1. The terms “Visitor” and “User” refer to users of this website (“Website”). By accessing this Website, you agree to the Terms and Conditions stated below. If you do not accept these terms, please exit the site. Institution 1, along with its business divisions, subsidiaries, associate companies, and investment entities (both domestic and international), reserves the right to update these Terms and Conditions at any time. Users should periodically review this page, as these terms are binding.
          </p>
  
          <div className="w-full">
            <h2 className="text-2xl font-semibold text-indigo-600 mb-4">Use of Content</h2>
            <p className="text-lg text-gray-800 mb-4">
              All logos, brands, headings, labels, names, signatures, numerals, shapes, or any combinations thereof on this site are the properties of the Company or used under license by the Company and its associates. Unauthorized use of these properties or any other content on this site is strictly prohibited. You may not sell, modify, reproduce, display, publicly perform, distribute, or otherwise use the materials for any public or commercial purpose without written permission from the respective organization.
            </p>
          </div>
  
          <div className="w-full">
            <h2 className="text-2xl font-semibold text-indigo-600 mb-4">Acceptable Website Use</h2>
            
            <h3 className="text-xl font-semibold text-indigo-500 mb-2">A. Security Rules</h3>
            <p className="text-lg text-gray-800 mb-4">
              Visitors are prohibited from:
              <ol className="list-decimal list-inside ml-4">
                <li>Accessing data not intended for them or logging into unauthorized servers or accounts.</li>
                <li>Attempting to probe, scan, or test the vulnerability of a system or network or breach security without authorization.</li>
                <li>Interfering with service to any user, host, or network, including submitting a virus, overloading, flooding, mail bombing, or crashing.</li>
                <li>Sending unsolicited electronic mail, including promotions and advertisements of products or services.</li>
              </ol>
              Violations may result in civil or criminal liability. The Company and its associates have the right to investigate and cooperate with law enforcement authorities in prosecuting users involved in such violations.
            </p>
            
            <h3 className="text-xl font-semibold text-indigo-500 mb-2">B. General Rules</h3>
            <p className="text-lg text-gray-800 mb-4">
              Visitors may not use the Website to:
              <ol className="list-decimal list-inside ml-4">
                <li>Transmit, distribute, store, or destroy material that could constitute or encourage conduct considered a criminal offense or violate any law.</li>
                <li>Infringe the intellectual property rights, privacy, or publicity of others.</li>
                <li>Transmit defamatory, obscene, pornographic, abusive, or hateful materials.</li>
              </ol>
            </p>
          </div>
  
          <div className="w-full">
            <h2 className="text-2xl font-semibold text-indigo-600 mb-4">Indemnity</h2>
            <p className="text-lg text-gray-800 mb-4">
              Users agree to indemnify and hold harmless the Company, its officers, directors, employees, and agents from any claims, actions, demands, liabilities, losses, or damages arising from their use of the Website or their breach of these terms.
            </p>
          </div>
  
          <div className="w-full">
            <h2 className="text-2xl font-semibold text-indigo-600 mb-4">Liability</h2>
            <p className="text-lg text-gray-800 mb-4">
              The Company, its group companies, directors, officers, and employees shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages resulting from the use or inability to use the service, the procurement of substitute goods or services, unauthorized access to or alteration of transmissions or data, or any other matter relating to the service. This includes, but is not limited to, damages for loss of profits, use, data, or other intangibles, even if the Company has been advised of the possibility of such damages. The Company shall not be liable for any interruptions, suspensions, or terminations of service, regardless of the cause. In no event shall the Company’s total liability to the User exceed the amount paid by the User to the Company, if any, related to the cause of action.
            </p>
          </div>
  
          <div className="w-full">
            <h2 className="text-2xl font-semibold text-indigo-600 mb-4">Disclaimer of Consequential Damages</h2>
            <p className="text-lg text-gray-800 mb-4">
              The Company and its associates shall not be liable for any incidental, consequential, or other damages, including lost profits, damage to computer hardware, loss of data, or business interruption, arising from the use or inability to use the Website or its materials, regardless of the legal theory, and even if advised of the possibility of such damages.
            </p>
          </div>
  
          <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
        </div>
      </div>
    </div>
  </div>
  
  
  
  )
}
