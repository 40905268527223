import React from 'react'
import { useState } from 'react';

export default function Dsasheet() {
   
    const sheet =[
       {
        type:"Array",
        questions:[
            {
                q: "Reverse an Array/String",
                link: "https://leetcode.com/problems/reverse-string/",
                article: ""
            },
            {
                q: "Find the maximum and minimum element in an array",
                link: "https://leetcode.com/problems/removing-minimum-and-maximum-from-array/description/",
                article: ""
            },
            {
                q: "Find the 'Kth' max and min element of an array",
                link: "https://leetcode.com/problems/kth-largest-element-in-an-array/",
                article: ""
            },
            {
                q: "Given an array which consists of only 0, 1 and 2. Sort the array without using any sorting algorithm",
                link: "https://leetcode.com/problems/sort-colors/description/",
                article: ""
            },
            {
                q: "Move all the negative elements to one side of the array",
                link: "https://www.geeksforgeeks.org/problems/move-all-negative-elements-to-end1813/1",
                article: ""
            },
            {
                q: "Find the Union and Intersection of the two sorted arrays",
                link: "https://leetcode.com/problems/intersection-of-two-arrays/description/",
                article: ""
            },
            {
                q: "Write a program to cyclically rotate an array by one",
                link: "https://leetcode.com/problems/rotate-array/description/",
                article: ""
            },
            {
                q: "Find Largest sum contiguous Subarray [V. IMP]",
                link: "https://leetcode.com/problems/maximum-subarray/description/",
                article: ""
            },
            {
                q: "Minimize the maximum difference between heights [V.IMP]",
                link: "https://www.geeksforgeeks.org/problems/minimize-the-heights3351/1",
                article: ""
            },
            {
                q: "Minimum no. of Jumps to reach end of an array",
                link: "https://leetcode.com/problems/jump-game-ii/description/",
                article: ""
            },
            {
                q: "Find duplicate in an array of N+1 Integers",
                link: "https://leetcode.com/problems/find-the-duplicate-number/description/",
                article: ""
            },
            {
                q: "Merge 2 sorted arrays without using Extra space",
                link: "https://leetcode.com/problems/merge-sorted-array/description/",
                article: ""
            },
            {
                q: "Kadane’s Algo [V.V.V.V.V IMP]",
                link: "https://leetcode.com/problems/maximum-subarray/description/",
                article: ""
            },
            {
                q: "Merge Intervals",
                link: "https://leetcode.com/problems/merge-intervals/description/",
                article: ""
            },
            {
                q: "Next Permutation",
                link: "https://leetcode.com/problems/next-permutation/description/",
                article: ""
            },
            {
                q: "Count Inversion",
                link: "https://leetcode.com/problems/reverse-pairs/solutions/892433/explainedeasy-c-inversion-count-using-merge-sort-100-faster/",
                article: ""
            },
            {
                q: "Best time to buy and Sell stock",
                link: "https://leetcode.com/problems/best-time-to-buy-and-sell-stock/description/",
                article: ""
            },
            {
                q: "Find all pairs on integer array whose sum is equal to given number",
                link: "https://leetcode.com/problems/finding-pairs-with-a-certain-sum/description/",
                article: ""
            },
            {
                q: "Find common elements In 3 sorted arrays",
                link: "https://leetcode.com/problems/intersection-of-three-sorted-arrays/description/",
                article: ""
            },
            {
                q: "Rearrange the array in alternating positive and negative items with O(1) extra space",
                link: "https://leetcode.com/problems/rearrange-array-elements-by-sign/description/",
                article: ""
            },
            {
                q: "Find if there is any subarray with sum equal to 0",
                link: "https://leetcode.com/problems/subarray-sum-equals-k/",
                article: ""
            },
            {
                q: "Find factorial of a large number",
                link: "https://www.hackerearth.com/practice/notes/factorial-of-large-number/",
                article: ""
            },
            {
                q: "Find maximum product subarray",
                link: "https://leetcode.com/problems/maximum-product-subarray/",
                article: ""
            },
            {
                q: "Find longest consecutive subsequence",
                link: "https://leetcode.com/problems/longest-consecutive-sequence/",
                article: ""
            },
            {
                q: "Given an array of size n and a number k, find all elements that appear more than 'n/k' times",
                link: "https://leetcode.com/problems/majority-element-ii/description/",
                article: ""
            },
            {
                q: "Maximum profit by buying and selling a share at most twice",
                link: "",
                article: ""
            },
            {
                q: "Find whether an array is a subset of another array",
                link: "",
                article: ""
            },
            {
                q: "Find the triplet that sum to a given value",
                link: "https://leetcode.com/problems/best-time-to-buy-and-sell-stock-iii/description/",
                article: ""
            },
            {
                q: "Trapping Rain water problem",
                link: "https://leetcode.com/problems/trapping-rain-water/description/",
                article: ""
            },
            {
                q: "Chocolate Distribution problem",
                link: "https://www.geeksforgeeks.org/problems/chocolate-distribution-problem3825/1",
                article: ""
            },
            {
                q: "Smallest Subarray with sum greater than a given value",
                link: "https://leetcode.com/problems/minimum-size-subarray-sum/description/",
                article: ""
            },
            {
                q: "Three way partitioning of an array around a given value",
                link: "https://leetcode.com/problems/partition-array-into-three-parts-with-equal-sum/description/",
                article: ""
            },
            {
                q: "Minimum swaps required bring elements less equal K together",
                link: "https://leetcode.com/problems/minimum-adjacent-swaps-for-k-consecutive-ones/description/",
                article: ""
            },
            {
                q: "Minimum no. of operations required to make an array palindrome",
                link: "https://leetcode.com/problems/minimum-number-of-moves-to-make-palindrome/description/",
                article: ""
            },
           
            {
                q: "Median of 2 sorted arrays of different size",
                link: "",
                article: "https://leetcode.com/problems/median-of-two-sorted-arrays/description/"
            }
        ]
      },
      {
        type:"Matrix",
        questions : [
            {
                q: "Spiral traversal on a Matrix",
                link: "https://leetcode.com/problems/spiral-matrix/description/",
                article: ""
            },
            {
                q: "Search an element in a Matrix",
                link: "https://leetcode.com/problems/search-a-2d-matrix/description/",
                article: ""
            },
            {
                q: "Find median in a row wise sorted matrix",
                link: "https://leetcode.com/problems/median-of-a-row-wise-sorted-matrix/description/",
                article: ""
            },
            {
                q: "Find row with maximum no. of 1’s",
                link: "https://leetcode.com/problems/row-with-maximum-ones/description/",
                article: ""
            },
            {
                q: "Print elements in sorted order using row-column wise sorted matrix",
                link: "https://www.geeksforgeeks.org/problems/sorted-matrix2333/1",
                article: ""
            },
            {
                q: "Maximum size rectangle",
                link: "https://leetcode.com/problems/maximal-rectangle/",
                article: ""
            },
            {
                q: "Find a specific pair in matrix",
                link: "https://leetcode.com/problems/finding-pairs-with-a-certain-sum/description/",
                article: "NA"
            },
            {
                q: "Rotate matrix by 90 degrees",
                link: "https://leetcode.com/problems/rotate-image/description/",
                article: ""
            },
            {
                q: "Kth smallest element in a row-column wise sorted matrix",
                link: "https://leetcode.com/problems/kth-smallest-element-in-a-sorted-matrix/description/",
                article: ""
            },
            {
                q: "Common elements in all rows of a given matrix",
                link: "https://leetcode.com/problems/find-smallest-common-element-in-all-rows/description/",
                article: ""
            }
        ]
      },
      {
        type:"Strings",
        questions :[
            {
                q: "Reverse a String",
                link: "https://leetcode.com/problems/reverse-string/",
                article: ""
            },
            {
                q: "Check whether a String is Palindrome or not",
                link: "https://leetcode.com/problems/valid-palindrome/description/",
                article: ""
            },
            {
                q: "Find Duplicate characters in a string",
                link: "https://leetcode.com/problems/remove-duplicate-letters/",
                article: "NA"
            },
           
            {
                q: "Write a Code to check whether one string is a rotation of another",
                link: "https://leetcode.com/problems/rotate-string/description/",
                article: ""
            },
            {
                q: "Write a Program to check whether a string is a valid shuffle of two strings or not",
                link: "https://leetcode.com/problems/shuffle-string/description/",
                article: "NA"
            },
            {
                q: "Count and Say problem",
                link: "https://leetcode.com/problems/count-and-say/",
                article: ""
            },
            {
                q: "Write a program to find the longest Palindrome in a string. [Longest palindromic Substring]",
                link: "https://leetcode.com/problems/longest-palindromic-substring/",
                article: ""
            },
            {
                q: "Find Longest Recurring Subsequence in String",
                link: "https://leetcode.com/problems/longest-subsequence-repeated-k-times/",
                article: ""
            },
            {
                q: "Print all Subsequences of a string",
                link: "https://leetcode.com/problems/is-subsequence/description/",
                article: "NA"
            },
            {
                q: "Print all the permutations of the given string",
                link: "https://leetcode.com/problems/permutation-in-string/",
                article: ""
            },
            {
                q: "Split the Binary string into two substring with equal 0’s and 1’s",
                link: "https://leetcode.com/problems/count-binary-substrings/",
                article: "NA"
            },
            // {
            //     q: "Word Wrap Problem [VERY IMP]",
            //     link: "https://leetcode.com/problems/word-break/description/",
            //     article: ""
            // },
            {
                q: "EDIT Distance [Very Imp]",
                link: "https://leetcode.com/problems/edit-distance/",
                article: ""
            },
            {
                q: "Find next greater number with same set of digits. [Very Very IMP]",
                link: "https://leetcode.com/problems/next-greater-element-iii/description/",
                article: ""
            },
            {
                q: "Balanced Parenthesis problem. [Imp]",
                link: "https://leetcode.com/problems/valid-parentheses/description/",
                article: ""
            },
            {
                q: "Word break Problem [Very Imp]",
                link: "https://leetcode.com/problems/word-break/description/",
                article: ""
            },
            {
                q: "Rabin Karp Algorithm",
                link: "https://leetcode.com/problems/find-the-index-of-the-first-occurrence-in-a-string/solutions/279941/rabin-karp-with-comments/",
                article: ""
            },
            {
                q: "KMP Algorithm",
                link: "https://leetcode.com/problems/longest-happy-prefix/solutions/549465/kmp-algorithm/",
                article: ""
            },
            // {
            //     q: "Convert a Sentence into its equivalent mobile numeric keypad sequence",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Minimum number of bracket reversals needed to make an expression balanced",
                link: "leetcode.com/problems/minimum-number-of-swaps-to-make-the-string-balanced/description",
                article: ""
            },
            {
                q: "Count All Palindromic Subsequence in a given String",
                link: "https://leetcode.com/problems/count-palindromic-subsequences/description/",
                article: ""
            },
            {
                q: "Count of number of given string in 2D character array",
                link: "https://leetcode.com/problems/count-the-number-of-consistent-strings/description/",
                article: ""
            },
            {
                q: "Search a Word in a 2D Grid of characters",
                link: "https://leetcode.com/problems/word-search/description/",
                article: ""
            },
            {
                q: "Boyer Moore Algorithm for Pattern Searching",
                link: "https://leetcode.com/problems/find-the-index-of-the-first-occurrence-in-a-string/solutions/744624/boyer-moore-substring-search-using-both-bad-character-and-good-suffix-heuristics-explained/",
                article: ""
            },
            {
                q: "Converting Roman Numerals to Decimal",
                link: "https://leetcode.com/problems/roman-to-integer/",
                article: ""
            },
            {
                q: "Longest Common Prefix",
                link: "https://leetcode.com/problems/longest-common-prefix/",
                article: ""
            },
            {
                q: "Number of flips to make binary string alternate",
                link: "https://leetcode.com/problems/minimum-number-of-flips-to-make-the-binary-string-alternating/description/",
                article: ""
            },
            {
                q: "Find the first repeated word in string",
                link: "",
                article: ""
            },
            {
                q: "Minimum number of swaps for bracket balancing",
                link: "https://leetcode.com/problems/repeated-string-match/description/",
                article: ""
            },
            {
                q: "Find the longest common subsequence between two strings",
                link: "https://leetcode.com/problems/longest-common-subsequence/",
                article: ""
            },
            {
                q: "Program to generate all possible valid IP addresses from given string",
                link: "https://leetcode.com/problems/restore-ip-addresses/description/",
                article: ""
            },
            {
                q: "Write a program to find the smallest window that contains all characters of string itself",
                link: "https://leetcode.com/problems/minimum-window-substring/description/",
                article: ""
            },
            {
                q: "Rearrange characters in a string such that no two adjacent are same",
                link: "https://leetcode.com/problems/reorganize-string/description/",
                article: ""
            },
            {
                q: "Minimum characters to be added at front to make string palindrome",
                link: "https://leetcode.com/problems/minimum-insertion-steps-to-make-a-string-palindrome/description/",
                article: ""
            },
            {
                q: "Given a sequence of words, print all anagrams together",
                link: "https://leetcode.com/problems/group-anagrams/",
                article: ""
            },
         
            {
                q: "Recursively remove all adjacent duplicates",
                link: "https://leetcode.com/problems/remove-all-adjacent-duplicates-in-string/",
                article: ""
            },
            {
                q: "String matching where one string contains wildcard characters",
                link: "https://leetcode.com/problems/wildcard-matching/description/",
                article: ""
            },
       
            {
                q: "Transform One String to Another using Minimum Number of Given Operations",
                link: "https://leetcode.com/problems/minimum-moves-to-convert-string/",
                article: ""
            },
            {
                q: "Check if two given strings are isomorphic to each other",
                link: "https://leetcode.com/problems/isomorphic-strings/description/",
                article: ""
            },
            {
                q: "Find Words That Can Be Formed by Characters",
                link: "https://leetcode.com/problems/find-words-that-can-be-formed-by-characters/description/",
                article: ""
            }
        ]
      },
      {
        type:"Searching and Sorting",
        questions : [
            {
                q: "Find first and last positions of an element in a sorted array",
                link: "https://leetcode.com/problems/find-first-and-last-position-of-element-in-sorted-array/description/",
                article: ""
            },
         
            {
                q: "Search in a rotated sorted array",
                link: "https://leetcode.com/problems/search-in-rotated-sorted-array/",
                article: ""
            },
            {
                q: "Square root of an integer",
                link: "https://leetcode.com/problems/sqrtx",
                article: ""
            },
            {
                q: "Maximum and minimum of an array using minimum number of comparisons",
                link: "https://www.geeksforgeeks.org/problems/max-min/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: ""
            },
            {
                q: "Optimum location of point to minimize total distance",
                link: "https://leetcode.com/problems/maximize-distance-to-closest-person/description/",
                article: ""
            },
            {
                q: "Find the repeating and the missing",
                link: "https://leetcode.com/problems/find-missing-and-repeated-values/description/",
                article: ""
            },
            {
                q: "Find majority element",
                link: "https://leetcode.com/problems/majority-element/",
                article: ""
            },
            {
                q: "Searching in an array where adjacent differ by at most k",
                link: "https://leetcode.com/problems/k-diff-pairs-in-an-array/description/",
                article: ""
            },
            {
                q: "Find a pair with a given difference",
                link: "https://leetcode.com/problems/k-diff-pairs-in-an-array/description/",
                article: ""
            },
            {
                q: "Find four elements that sum to a given value",
                link: "https://leetcode.com/problems/4sum/",
                article: ""
            },
            {
                q: "Maximum sum such that no 2 elements are adjacent",
                link: "https://www.geeksforgeeks.org/problems/stickler-theif-1587115621/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: ""
            },
            {
                q: "Count triplet with sum smaller than a given value",
                link: "https://leetcode.com/problems/count-good-triplets/",
                article: ""
            },
          
            {
                q: "Product array Puzzle",
                link: "https://leetcode.com/problems/product-of-array-except-self/",
                article: ""
            },
            {
                q: "Sort array according to count of set bits",
                link: "https://leetcode.com/problems/sort-integers-by-the-number-of-1-bits/description/",
                article: ""
            },
            {
                q: "Minimum no. of swaps required to sort the array",
                link: "https://www.hackerrank.com/challenges/minimum-swaps-2/problem",
                article: ""
            },
            {
                q: "Bishu and Soldiers",
                link: "https://www.hackerearth.com/problem/algorithm/bishu-and-soldiers-227/",
                article: ""
            },
            {
                q: "Rasta and Kheshtak",
                link: "https://www.hackerearth.com/practice/algorithms/searching/binary-search/practice-problems/algorithm/rasta-and-kheshtak/discussion/",
                article: ""
            },
            {
                q: "Kth smallest number again",
                link: "https://www.hackerearth.com/practice/algorithms/searching/binary-search/practice-problems/algorithm/kth-smallest-number-again-2/",
                article: ""
            },
            {
                q: "Find pivot element in a sorted array",
                link: "https://leetcode.com/problems/find-pivot-index/description/",
                article: ""
            },
          
            {
                q: "Aggressive cows",
                link: "https://www.geeksforgeeks.org/problems/aggressive-cows/0",
                article: ""
            },
            {
                q: "Book Allocation Problem",
                link: "https://www.geeksforgeeks.org/problems/allocate-minimum-number-of-pages0937/1",
                article: ""
            },
           
            {
                q: "Job Scheduling Algo",
                link: "https://leetcode.com/problems/maximum-profit-in-job-scheduling/",
                article: ""
            },
            {
                q: "Missing Number in AP",
                link: "https://leetcode.com/problems/missing-number-in-arithmetic-progression/",
                article: ""
            },
            {
                q: "Smallest number with at least n trailing zeroes in factorial",
                link: "https://leetcode.com/problems/factorial-trailing-zeroes/",
                article: ""
            },
            {
                q: "Painters Partition Problem",
                link: "https://leetcode.com/problems/minimum-number-of-days-to-make-m-bouquets/solutions/693984/c-binary-search-similar-to-painters-partition-problem-detailed-explanation/",
                article: ""
            },
        
            {
                q: "Subset Sums",
                link: "https://leetcode.com/problems/find-array-given-subset-sums/",
                article: ""
            },
            {
                q: "Find the inversion count",
                link: "https://leetcode.com/problems/global-and-local-inversions/solutions/242101/count-inversions/",
                article: ""
            },
            {
                q: "Implement Merge-sort in-place",
                link: "https://www.geeksforgeeks.org/problems/inversion-of-array-1587115620/1",
                article: ""
            },
            {
                q: "Maximum number of partitions that can be sorted individually to make sorted",
                link: "https://www.geeksforgeeks.org/problems/maximum-number-of-partitions-that-can-be-sorted-individually-to-make-sorted2926/1",
                article: ""
            }
        ]
        
      },
      {
        type:"LinkedList",
        questions : [
            {
                q: "Write a Program to reverse the Linked List. (Both Iterative and recursive)",
                link: "https://leetcode.com/problems/reverse-linked-list/",
                article: ""
            },
            {
                q: "Reverse a Linked List in group of Given Size. [Very Imp]",
                link: "https://leetcode.com/problems/reverse-nodes-in-k-group/",
                article: ""
            },
            {
                q: "Write a program to Detect loop in a linked list.",
                link: "https://leetcode.com/problems/linked-list-cycle/",
                article: ""
            },
            {
                q: "Write a program to Delete loop in a linked list.",
                link: "https://www.geeksforgeeks.org/problems/remove-loop-in-linked-list/1/",
                article: ""
            },
            {
                q: "Find the starting point of the loop.",
                link: "https://leetcode.com/problems/linked-list-cycle-ii/description/",
                article: ""
            },
            {
                q: "Remove Duplicates in a sorted Linked List.",
                link: "https://leetcode.com/problems/remove-duplicates-from-sorted-list/",
                article: ""
            },
            {
                q: "Remove Duplicates in an Un-sorted Linked List.",
                link: "https://www.geeksforgeeks.org/problems/remove-duplicates-from-an-unsorted-linked-list/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: ""
            },
            {
                q: "Write a Program to Move the last element to Front in a Linked List.",
                link: "https://www.geeksforgeeks.org/problems/move-last-element-to-front-of-a-linked-list/0",
                article: ""
            },
            {
                q: "Add '1' to a number represented as a Linked List.",
                link: "https://www.geeksforgeeks.org/problems/add-1-to-a-number-represented-as-linked-list/1",
                article: ""
            },
            {
                q: "Add two numbers represented by linked lists.",
                link: "https://leetcode.com/problems/add-two-numbers/description/",
                article: ""
            },
            {
                q: "Intersection of two Sorted Linked List.",
                link: "https://leetcode.com/problems/intersection-of-two-linked-lists/description/",
                article: ""
            },
          
            {
                q: "Merge Sort For Linked lists.[Very Important]",
                link: "https://www.geeksforgeeks.org/problems/sort-a-linked-list/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: ""
            },
            {
                q: "Quicksort for Linked Lists.[Very Important]",
                link: "https://leetcode.com/problems/sort-list/solutions/228473/quicksort-for-listclean-and-easybut-not-fast/",
                article: ""
            },
            {
                q: "Find the middle Element of a linked list.",
                link: "https://leetcode.com/problems/middle-of-the-linked-list/description/",
                article: ""
            },
           
            {
                q: "Split a Circular linked list into two halves.",
                link: "https://leetcode.com/problems/split-linked-list-in-parts/description/",
                article: ""
            },
            {
                q: "Write a Program to check whether the Singly Linked list is a palindrome or not.",
                link: "https://leetcode.com/problems/palindrome-linked-list/",
                article: ""
            },
       
            {
                q: "Reverse a Doubly Linked list.",
                link: "https://www.hackerrank.com/challenges/reverse-a-doubly-linked-list/problem",
                article: ""
            },
            {
                q: "Find pairs with a given sum in a DLL.",
                link: "https://www.geeksforgeeks.org/problems/find-pairs-with-given-sum-in-doubly-linked-list/0",
                article: ""
            },
            {
                q: "Count triplets in a sorted DLL whose sum is equal to given value 'X'.",
                link: "https://www.geeksforgeeks.org/problems/count-triplets--141631/1",
                article: ""
            },
            {
                q: "Sort a 'k' sorted Doubly Linked list.[Very IMP]",
                link: "https://www.geeksforgeeks.org/problems/sort-a-k-sorted-doubly-linked-list/1",
                article: ""
            },
            {
                q: "Rotate Doubly Linked list by N nodes.",
                link: "https://www.javatpoint.com/program-to-rotate-doubly-linked-list-by-n-nodes",
                article: ""
            },
           
         
            {
                q: "Flatten a Linked List",
                link: "https://leetcode.com/problems/flatten-a-multilevel-doubly-linked-list/",
                article: ""
            },
            {
                q: "Sort a LL of 0’s, 1’s and 2’s",
                link: "https://www.geeksforgeeks.org/problems/given-a-linked-list-of-0s-1s-and-2s-sort-it/1",
                article: ""
            },
            {
                q: "Clone a linked list with next and random pointer",
                link: "https://leetcode.com/problems/copy-list-with-random-pointer/description/",
                article: ""
            },
            {
                q: "Merge K sorted Linked list",
                link: "https://leetcode.com/problems/merge-k-sorted-lists/description/",
                article: ""
            },
            {
                q: "Multiply 2 numbers represented by LL",
                link: "https://www.geeksforgeeks.org/problems/multiply-two-linked-lists/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: ""
            },
            {
                q: "Delete nodes which have a greater value on right side",
                link: "https://leetcode.com/problems/remove-nodes-from-linked-list/description/",
                article: ""
            },
            {
                q: "Segregate even and odd nodes in a Linked List",
                link: "ttps://leetcode.com/problems/odd-even-linked-list/description/",
                article: ""
            },
            {
                q: "Program for nth node from the end of a Linked List",
                link: "https://leetcode.com/problems/remove-nth-node-from-end-of-list/",
                article: ""
            }
        ]
        
      },
      {
        type:"Bit Manipulation",
        questions : [
            {
                q: "Count set bits ",
                link: "https://leetcode.com/problems/counting-bits/description/",
                article: ""
            },
            {
                q: "Find the two non-repeating elements in an array of repeating elements",
                link: "https://leetcode.com/problems/single-number-iii/",
                article: ""
            },
            {
                q: "Count number of bits to be flipped to convert A to B",
                link: "https://www.geeksforgeeks.org/count-number-of-bits-to-be-flipped-to-convert-a-to-b/",
                article: ""
            },
            {
                q: "Count total set bits in all numbers from 1 to n",
                link: "https://www.geeksforgeeks.org/problems/sherlock-and-his-enemies2304/1",
                article: ""
            },
            {
                q: "Program to find whether a number is a power of two",
                link: "https://leetcode.com/problems/power-of-two/description/",
                article: ""
            },
            {
                q: "Find position of the only set bit",
                link: "https://www.geeksforgeeks.org/problems/find-position-of-set-bit3706/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: ""
            },
            {
                q: "Copy set bits in a range",
                link: "https://www.geeksforgeeks.org/problems/copy-set-bits-in-range0623/1",
                article: ""
            },
            {
                q: "Divide two integers without using multiplication, division and mod operator",
                link: "https://leetcode.com/problems/divide-two-integers/description/",
                article: ""
            },
            // {
            //     q: "Calculate square of a number without using *, / and pow()",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Power Set",
                link: "https://leetcode.com/problems/subsets/",
                article: ""
            }
        ]
        
      },
      {
        type:"Greedy",
        questions : [
            {
                q: "Activity Selection Problem",
                link: "https://www.geeksforgeeks.org/problems/activity-selection-1587115620/1",
                article: ""
            },
            {
                q: "Job Sequencing Problem",
                link: "",
                article: ""
            },
            {
                q: "Huffman Coding",
                link: "https://leetcode.com/problems/encode-and-decode-tinyurl/solutions/302761/c-using-huffman-coding/",
                article: ""
            },
            {
                q: "Water Connection Problem",
                link: "vhttps://www.geeksforgeeks.org/problems/water-connection-problem5822/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: ""
            },
            {
                q: "Fractional Knapsack Problem",
                link: "https://www.geeksforgeeks.org/problems/fractional-knapsack-1587115620/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: ""
            },
            {
                q: "Greedy Algorithm to find Minimum number of Coins",
                link: "https://www.geeksforgeeks.org/problems/-minimum-number-of-coins4426/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: ""
            },
            {
                q: "Maximum trains for which stoppage can be provided",
                link: "https://www.geeksforgeeks.org/problems/maximum-trains-for-which-stoppage-can-be-provided/1",
                article: ""
            },
            {
                q: "Minimum Platforms Problem",
                link: "https://www.hackerearth.com/problem/algorithm/minimum-platforms-2/",
                article: ""
            },
            {
                q: "Buy Maximum Stocks if i stocks can be bought on i-th day",
                link: "https://www.hackerearth.com/problem/algorithm/minimum-platforms-2/",
                article: ""
            },
            {
                q: "Find the minimum and maximum amount to buy all N candies",
                link: "https://www.geeksforgeeks.org/problems/shop-in-candy-store1145/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: ""
            },
            {
                q: "Minimize Cash Flow among a given set of friends who have borrowed money from each other",
                link: "https://www.geeksforgeeks.org/problems/minimize-cash-flow/1",
                article: ""
            },
            {
                q: "MiMinimum Cost to Cut a Stick",
                link: "https://leetcode.com/problems/minimum-cost-to-cut-a-stick/description/",
                article: ""
            },
            {
                q: "Check if it is possible to survive on Island",
                link: "https://www.geeksforgeeks.org/problems/check-if-it-is-possible-to-survive-on-island4922/1",
                article: ""
            },
            {
                q: "Find maximum meetings in one room",
                link: "https://leetcode.com/problems/maximum-number-of-events-that-can-be-attended/",
                article: ""
            },
            {
                q: "Maximum product subset of an array",
                link: "https://www.geeksforgeeks.org/problems/maximum-product-subset-of-an-array/1",
                article: ""
            },
            {
                q: "Maximize array sum after K negations",
                link: "https://leetcode.com/problems/maximize-sum-of-array-after-k-negations/",
                article: ""
            },
            {
                q: "Maximize the sum of arr[i]*i",
                link: "https://www.geeksforgeeks.org/problems/maximize-arrii-of-an-array0026/1",
                article: ""
            },
            {
                q: "Maximum sum of absolute difference of an array",
                link: "https://leetcode.com/problems/sum-of-absolute-differences-in-a-sorted-array/description/",
                article: ""
            },
            {
                q: "Maximize sum of consecutive differences in a circular array",
                link: "https://leetcode.com/problems/maximum-sum-circular-subarray/description/1",
                article: ""
            },
            {
                q: "Minimum sum of absolute difference of pairs of two arrays",
                link: "https://leetcode.com/problems/minimum-absolute-sum-difference/description/",
                article: ""
            },
            {
                q: "Program for Shortest Job First (or SJF) CPU Scheduling",
                link: "https://www.geeksforgeeks.org/program-for-shortest-job-first-or-sjf-cpu-scheduling-set-1-non-preemptive/",
                article: ""
            },
            // {
            //     q: "Program for Least Recently Used (LRU) Page Replacement algorithm",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Smallest subset with sum greater than all other elements",
                link: "https://www.geeksforgeeks.org/problems/smallest-subset-with-greater-sum/1",
                article: ""
            },
            {
                q: "Chocolate Distribution Problem",
                link: "https://www.geeksforgeeks.org/problems/chocolate-distribution-problem3825/1",
                article: ""
            },
            {
                q: "DEFKIN - Defense of a Kingdom",
                link: "https://www.codechef.com/problems/WCE0004",
                article: ""
            },
            {
                q: "DIEHARD - DIE HARD",
                link: "https://codeforces.com/blog/entry/91475",
                article: ""
            },
            // {
            //     q: "GERGOVIA - Wine trading in Gergovia",
            //     link: "",
            //     article: ""
            // },
            // {
            //     q: "Picking Up Chicks",
            //     link: "",
            //     article: ""
            // },
            // {
            //     q: "CHOCOLA – Chocolate",
            //     link: "",
            //     article: ""
            // },
            // {
            //     q: "ARRANGE - Arranging Amplifiers",
            //     link: "",
            //     article: ""
            // },
            {
                q: "K Centers Problem",
                link: "https://www.geeksforgeeks.org/problems/k-centers-problem/0",
                article: ""
            },
            {
                q: "Minimum Cost of ropes",
                link: "https://www.geeksforgeeks.org/problems/minimum-cost-of-ropes-1587115620/1",
                article: ""
            },
            {
                q: "Find smallest number with given number of digits and sum of digits",
                link: "https://www.geeksforgeeks.org/problems/smallest-number5829/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: ""
            },
            // {
            //     q: "Rearrange characters in a string such that no two adjacent are same",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Find maximum sum possible equal sum of three stacks",
                link: "https://www.geeksforgeeks.org/problems/find-maximum-equal-sum-of-three-stacks/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: ""
            }
        ]
       },
      {
        type:"Backtracking",
        questions : [
            {
                q: "Rat in a maze Problem",
                link: "https://www.geeksforgeeks.org/problems/rat-in-a-maze-problem/1",
                article: ""
            },
            {
                q: "Printing all solutions in N-Queen",
                link: "https://leetcode.com/problems/n-queens/",
                article: ""
            },
            {
                q: "Word Break Problem using Backtracking",
                link: "https://leetcode.com/problems/word-break-ii/description/",
                article: ""
            },
            {
                q: "Remove Invalid Parentheses",
                link: "https://leetcode.com/problems/remove-invalid-parentheses/description/",
                article: ""
            },
            {
                q: "Sudoku Solver",
                link: "https://leetcode.com/problems/sudoku-solver/",
                article: ""
            },
            {
                q: "M Coloring Problem",
                link: "https://leetcode.com/problems/flower-planting-with-no-adjacent/solutions/2121063/c-m-coloring-problem/",
                article: ""
            },
            {
                q: "Print all palindromic partitions of a string",
                link: "https://leetcode.com/problems/palindrome-partitioning/description/",
                article: ""
            },
            {
                q: "Subset Sum Problem",
                link: "https://leetcode.com/problems/partition-equal-subset-sum/description/",
                article: ""
            },
            {
                q: "The Knight’s tour problem",
                link: "https://leetcode.com/problems/check-knight-tour-configuration/description/",
                article: ""
            },
            {
                q: "Tug of War",
                link: "https://leetcode.com/problems/last-stone-weight-ii/solutions/1197491/python-knapsack-solution-relatable-tug-of-war-backtrack-problem/",
                article: ""
            },
            {
                q: "Find shortest safe route in a path with landmines",
                link: "https://leetcode.com/problems/shortest-path-in-a-grid-with-obstacles-elimination/description/",
                article: ""
            },
            {
                q: "Combinational Sum",
                link: "https://leetcode.com/problems/combination-sum/",
                article: ""
            },
            {
                q: "Find Maximum number possible by doing at-most K swaps",
                link: "https://leetcode.com/problems/minimum-possible-integer-after-at-most-k-adjacent-swaps-on-digits/description/",
                article: ""
            },
            {
                q: "Print all Permutations",
                link: "https://leetcode.com/problems/permutations/description/",
                article: ""
            },
            {
                q: "Find if there is a path of more than k length from a source",
                link: "https://www.geeksforgeeks.org/problems/path-of-greater-than-equal-to-k-length1034/1",
                article: ""
            },
            {
                q: "Longest Increasing Path in a Matrix",
                link: "https://leetcode.com/problems/longest-increasing-path-in-a-matrix/description/",
                article: ""
            },
            // {
            //     q: "Print all possible paths from top left to bottom right of a mXn matrix",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Partition of a set into K subsets with equal sum",
                link: "https://leetcode.com/problems/partition-to-k-equal-sum-subsets/description/",
                article: ""
            },
            {
                q: "Permutation Sequence",
                link: "https://leetcode.com/problems/permutation-sequence/description/",
                article: ""
            }
        ]
       },
      {
        type:"Dynamic Programming",
        questions : [
            {
                q: "Coin Change Problem",
                link: "https://leetcode.com/problems/coin-change/description/",
                article: ""
            },
            {
                q: "Knapsack Problem",
                link: "https://www.geeksforgeeks.org/problems/0-1-knapsack-problem0945/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: ""
            },
            {
                q: "Binomial Coefficient Problem",
                link: "https://www.geeksforgeeks.org/problems/ncr1019/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: ""
            },
            // {
            //     q: "Permutation Coefficient Problem",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Program for nth Catalan Number",
                link: "https://www.geeksforgeeks.org/problems/nth-catalan-number0817/1",
                article: "#Medium #Amazon"
            },
            {
                q: "Matrix Chain Multiplication",
                link: "https://www.geeksforgeeks.org/problems/matrix-chain-multiplication0303/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Hard  #Flipkart #Microsoft #FactSet"
            },
            {
                q: "Edit Distance",
                link: "https://www.geeksforgeeks.org/problems/edit-distance3702/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Hard #Amazon #Microsoft #GoldmanSachs #Google"
            },
            {
                q: "Subset Sum Problem",
                link: "https://www.geeksforgeeks.org/problems/subset-sum-problem-1611555638/1",
                article: "#Medium #Amazon #Microsoft"
            },
            {
                q: "Friends Pairing Problem",
                link: "https://www.geeksforgeeks.org/problems/friends-pairing-problem5425/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium "
            },
            {
                q: "Gold Mine Problem",
                link: "https://www.geeksforgeeks.org/problems/gold-mine-problem2608/1",
                article: "#Medium #Amazon #Samsung"
            },
            {
                q: "Assembly Line Scheduling Problem",
                link: "https://www.geeksforgeeks.org/problems/assembly-line-scheduling/1",
                article: "#Medium"
            },
            {
                q: "Painting the Fence problem",
                link: "https://www.geeksforgeeks.org/problems/painting-the-fence3727/1",
                article: "#Medium"
            },
            {
                q: "Maximize The Cut Segments",
                link: "https://www.geeksforgeeks.org/problems/cutted-segments1642/1",
                article: "#Medium #Microsoft #OYO Rooms"
            },
            {
                q: "Longest Common Subsequence",
                link: "https://leetcode.com/problems/longest-common-subsequence/",
                article: "#Medium #Paytm #MakeMyTrip"
            },
            {
                q: "Longest Repeated Subsequence",
                link: "https://leetcode.com/problems/longest-subsequence-repeated-k-times/",
                article: "#Medium #Amazon #Google"
            },
            {
                q: "Longest Increasing Subsequence",
                link: "https://www.google.co.in/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiToZ3X2LaGAxXGR2wGHaENBLIQFnoECAYQAQ&url=https%3A%2F%2Fleetcode.com%2Fproblems%2Flongest-increasing-subsequence%2F&usg=AOvVaw2g9FxazN-swz_X55rpg6Kn&opi=89978449",
                article: "#Hard "
            },
            {
                q: "Space Optimized Solution of LCS",
                link: "https://leetcode.com/problems/longest-common-subsequence/solutions/1348817/lcs-space-optimized-solution-normal-soution/",
                article: "#Hard"
            },
            {
                q: "LCS (Longest Common Subsequence) of three strings",
                link: "https://www.geeksforgeeks.org/problems/lcs-of-three-strings0028/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium #DE Shaw"
            },
            {
                q: "Maximum Sum Increasing Subsequence",
                link: "https://www.geeksforgeeks.org/problems/maximum-sum-increasing-subsequence4749/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium #Morgan Stanley #Amazon"
            },
            {
                q: "Count all subsequences having product less than K",
                link: "https://leetcode.com/problems/subarray-product-less-than-k/",
                article: "#Medium "
            },
            {
                q: "Longest subsequence such that difference between adjacent is one",
                link: "https://www.geeksforgeeks.org/problems/longest-subsequence-such-that-difference-between-adjacents-is-one4724/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium #Flipkart"
            },
            {
                q: "Maximum subsequence sum such that no three are consecutive",
                link: "https://www.geeksforgeeks.org/problems/max-sum-without-adjacents-2/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium"
            },
            {
                q: "Egg Dropping Problem",
                link: "https://leetcode.com/problems/super-egg-drop/",
                article: "#Hard #nearbuy #Opera #Philips"
            },
            {
                q: "Maximum Length Chain of Pairs",
                link: "https://leetcode.com/problems/maximum-length-of-pair-chain/description/",
                article: "#Medium #Amazon #Microsoft"
            },
            {
                q: "Maximum size square sub-matrix with all 1s",
                link: "https://www.geeksforgeeks.org/problems/largest-square-formed-in-a-matrix0806/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium #Amazon #Samsung"
            },
            {
                q: "Maximum sum of pairs with specific difference",
                link: "https://www.geeksforgeeks.org/problems/pairs-with-specific-difference1533/1",
                article: "#Easy "
            },
            {
                q: "Min Cost Path Problem",
                link: "https://www.geeksforgeeks.org/problems/minimum-cost-path3833/1",
                article: "#Hard #Samsung #MakeMyTrip"
            },
            {
                q: "Maximum difference of zeros and ones in binary string",
                link: "https://www.geeksforgeeks.org/problems/maximum-difference-of-zeros-and-ones-in-binary-string4111/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium"
            },
            {
                q: "Minimum number of jumps to reach end",
                link: "https://leetcode.com/problems/jump-game-ii/",
                article: "#Medium #Google #Facebook"
            },
            {
                q: "Minimum cost to fill given weight in a bag",
                link: "https://www.geeksforgeeks.org/problems/minimum-cost-to-fill-given-weight-in-a-bag1956/1",
                article: "#Medium"
            },
            {
                q: "Minimum removals from array to make max –min <= K",
                link: "https://www.geeksforgeeks.org/problems/array-removals/1",
                article: "#Medium"
            },
            {
                q: "Longest Common Substring",
                link: "https://www.geeksforgeeks.org/problems/longest-common-substring1452/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium #Morgan Stanley #Amazon"
            },
            {
                q: "Count number of ways to reach a given score in a game",
                link: "https://www.geeksforgeeks.org/problems/reach-a-given-score-1587115621/1",
                article: "#Easy"
            },
           
            // {
            //     q: "LargestSum Contiguous Subarray [V>V>V>V IMP]",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Smallest sum contiguous subarray",
                link: "https://www.geeksforgeeks.org/problems/smallest-sum-contiguous-subarray/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium"
            },
            {
                q: "Unbounded Knapsack (Repetition of items allowed)",
                link: "https://www.geeksforgeeks.org/problems/knapsack-with-duplicate-items4201/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Amazon #Google #Medium"
            },
            {
                q: "Word Break Problem",
                link: "https://www.geeksforgeeks.org/problems/word-break1352/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium #Zoho #IBM"
            },
            {
                q: "Largest Independent Set Problem",
                link: "https://www.geeksforgeeks.org/problems/largest-independent-set-problem/1",
                article: "#Medium"
            },
            {
                q: "Partition problem",
                link: "https://www.geeksforgeeks.org/partition-problem-dp-18/",
                article: "#Medium"
            },
            {
                q: "Longest Palindromic Subsequence",
                link: "https://leetcode.com/problems/longest-palindromic-subsequence/description/",
                article: "#Medium #Amazon #Google"
            },
            {
                q: "Count All Palindromic Subsequence in a given String",
                link: "https://www.geeksforgeeks.org/problems/count-palindromic-subsequences/1",
                article: "#Medium"
            },
            {
                q: "Longest Palindromic Substring",
                link: "https://leetcode.com/problems/longest-palindromic-substring/",
                article: "#Medium #Qualcomm #Groupon"
            },
            {
                q: "Longest alternating subsequence",
                link: "https://www.geeksforgeeks.org/problems/longest-alternating-subsequence5951/1",
                article: "#Medium"
            },
           
            {
                q: "Coin game winner where every player has three choices",
                link: "https://www.geeksforgeeks.org/problems/geek-and-its-game-of-coins4043/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium"
            },
            {
                q: "Count Derangements (Permutation such that no element appears in its original position) [ IMPORTANT ]",
                link: "https://leetcode.com/discuss/general-discussion/1367659/count-derangements-permutation-such-that-no-element-appears-in-its-original-position",
                article: ""
            },
            // {
            //     q: "Maximum profit by buying and selling a share at most twice [ IMP ]",
            //     link: "https://www.geeksforgeeks.org/count-derangements-permutation-such-that-no-element-appears-in-its-original-position/",
            //     article: ""
            // },
            {
                q: "Optimal Strategy for a Game",
                link: "https://leetcode.com/problems/predict-the-winner/solutions/1592935/optimal-game-strategy-minmax-conceptdynamic-programming/",
                article: "#Hard"
            },
            {
                q: "Optimal Binary Search Tree",
                link: "https://www.geeksforgeeks.org/optimal-binary-search-tree-dp-24/",
                article: "#Hard"
            },
            // {
            //     q: "Palindrome Partitioning Problem",
            //     link: "",
            //     article: ""
            // },
            // {
            //     q: "Word Wrap Problem",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Mobile Numeric Keypad Problem [ IMP ]",
                link: "https://www.geeksforgeeks.org/problems/mobile-numeric-keypad5456/1",
                article: "#MAQ Software #Sprinklr #Medium"
            },
            {
                q: "Boolean Parenthesization Problem",
                link: "https://www.geeksforgeeks.org/problems/boolean-parenthesization5610/1",
                article: "#Hard #Intuit #Linkedin"
            },
            {
                q: "Largest rectangular sub-matrix whose sum is 0",
                link: "https://www.geeksforgeeks.org/problems/largest-rectangular-sub-matrix-whose-sum-is-0/1",
                article: "#Hard"
            },
            // {
            //     q: "Largest area rectangular sub-matrix with equal number of 1’s and 0’s [ IMP ]",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Maximum sum rectangle in a 2D matrix",
                link: "https://www.geeksforgeeks.org/problems/maximum-sum-rectangle2948/1",
                article: "#Medium #Samsung #FactSet"
                
            },
            {
                q: "Maximum profit by buying and selling a share at most k times",
                link: "https://leetcode.com/problems/best-time-to-buy-and-sell-stock-iv/description/",
                article: "#Hard #Accolite #Amazon"
            },
            {
                q: "Find if a string is interleaved of two other strings",
                link: "https://www.geeksforgeeks.org/problems/interleaved-strings/1",
                article: "#Medium #Paytm #Yahoo"
            },
            {
                q: "Maximum Length of Pair Chain",
                link: "https://leetcode.com/problems/maximum-length-of-pair-chain/",
                article: "#Medium"
            }
        ]
     },
      {
        type:"Stacks and Queues",
        questions : [
            {
                q: "Implement Stack from stacks",
                link: "https://leetcode.com/problems/min-stack/description/",
                article: "#Medium"
            },
            {
                q: "Implement Queue from stacks",
                link: "https://leetcode.com/problems/implement-queue-using-stacks/description/",
                article: "#Easy"
            },
            {
                q: "Implement 2 stack in an array",
                link: "https://leetcode.com/problems/build-an-array-with-stack-operations/description/",
                article: "#Easy #Samsung #Snapdeal"
            },
            {
                q: "Find the middle element of a stack",
                link: "https://www.geeksforgeeks.org/problems/delete-middle-element-of-a-stack/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Easy #Amazon"
            },
            {
                q: "Implement “N” stacks in an Array",
                link: "https://www.naukri.com/code360/problems/n-stacks-in-an-array_1164271",
                article: "#Hard"
            },
          
            {
                q: "Reverse a String using Stack",
                link: "https://leetcode.com/problems/reverse-string/description/",
                article: "#Easy "
            },
            {
                q: "Design a Stack that supports getMin() in O(1) time and O(1) extra space.",
                link: "https://leetcode.com/problems/min-stack/",
                article: "#SAP Labs #Sapient #One97"
            },
            {
                q: "Find the next Greater element",
                link: "https://leetcode.com/problems/next-greater-element-i/description/",
                article: "#Easy $Microsoft #MakeMyTrip #Adobe"
            },
            // {
            //     q: "The celebrity Problem",
            //     link: "",
            //     article: ""
            // },
            // {
            //     q: "Arithmetic Expression evaluation",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Evaluation of Postfix expression",
                link: "https://www.geeksforgeeks.org/problems/evaluation-of-postfix-expression1735/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Easy"
            },
            {
                q: "Implement a method to insert an element at its bottom without using any other data structure.",
                link: "https://www.geeksforgeeks.org/problems/insert-an-element-at-the-bottom-of-a-stack/1",
                article: "#Easy"
            },
            {
                q: "Reverse a stack using recursion",
                link: "https://www.geeksforgeeks.org/problems/reverse-a-stack/1",
                article: "#Medium "
            },
            {
                q: "Sort a Stack using recursion",
                link: "https://www.geeksforgeeks.org/problems/sort-a-stack/1",
                article: "#Medium "
            },
            // {
            //     q: "Merge Overlapping Intervals",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Largest rectangular Area in Histogram",
                link: "https://leetcode.com/problems/largest-rectangle-in-histogram/",
                article: "#Hard #Microsoft #Google"
            },
            {
                q: "Length of the Longest Valid Substring",
                link: "https://leetcode.com/problems/length-of-the-longest-valid-substring/description/",
                article: "#Hard #Microsoft #Google "
            },
            {
                q: "Expression contains redundant bracket or not",
                link: "https://www.geeksforgeeks.org/problems/expression-contains-redundant-bracket-or-not/1",
                article: "#Medium "
            },
            {
                q: "Implement Stack using Queue",
                link: "https://leetcode.com/problems/implement-stack-using-queues/description/",
                article: "#Easy"
            },
            {
                q: "Implement Stack using Deque",
                link: "https://leetcode.com/problems/implement-stack-using-queues/description/",
                article: "#Easy  "
            },
            {
                q: "Stack Permutations (Check if an array is stack permutation of other)",
                link: "https://www.geeksforgeeks.org/problems/stack-permutations/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium "
            },
          
            {
                q: "Implement “n” queue in an array",
                link: "https://www.naukri.com/code360/problems/n-queue-using-array_1170053",
                article: "#Easy "
            },
            {
                q: "Implement a Circular queue",
                link: "https://leetcode.com/problems/design-circular-queue/",
                article: ""
            },
            {
                q: "LRU Cache Implementation",
                link: "https://leetcode.com/problems/lru-cache/",
                article: "#Medium "
            },
            {
                q: "Reverse a Queue using recursion",
                link: "https://www.naukri.com/code360/library/reversing-a-queue",
                article: "#Medium "
            },
            {
                q: "Reverse the first “K” elements of a queue",
                link: "https://www.geeksforgeeks.org/problems/reverse-first-k-elements-of-queue/1",
                article: "#Easy #Amazon"
            },
            {
                q: "Interleave the first half of the queue with second half",
                link: "https://www.geeksforgeeks.org/problems/interleave-the-first-half-of-the-queue-with-second-half/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "$Medium"
            },
            {
                q: "Find the first circular tour that visits all Petrol Pumps",
                link: "https://www.geeksforgeeks.org/problems/circular-tour-1587115620/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium #Zoho #Flipkart"
            },
            {
                q: "Minimum time required to rot all oranges",
                link: "https://leetcode.com/problems/rotting-oranges/",
                article: "#Medium #Microsoft"
            },
            {
                q: "Distance of nearest cell having 1 in a binary matrix",
                link: "https://www.geeksforgeeks.org/problems/distance-of-nearest-cell-having-1-1587115620/1",
                article: "#Medium "
            },
            {
                q: "First negative integer in every window of size “k”",
                link: "https://www.geeksforgeeks.org/problems/first-negative-integer-in-every-window-of-size-k3345/1",
                article: "#Medium #Amazon"
            },
            {
                q: "Check if all levels of two trees are anagrams or not.",
                link: "https://www.geeksforgeeks.org/problems/check-if-all-levels-of-two-trees-are-anagrams-or-not/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium "
            },
            {
                q: "Sum of minimum and maximum elements of all subarrays of size “k”.",
                link: "https://www.geeksforgeeks.org/problems/maximum-of-all-subarrays-of-size-k3101/1",
                article: "#Medium #Directi #Google"
            },
            {
                q: "Minimum sum of squares of character counts in a given string after removing “k” characters.",
                link: "https://www.geeksforgeeks.org/problems/game-with-string4100/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium #Amazon"
            },
            {
                q: "Queue based approach or first non-repeating character in a stream.",
                link: "https://www.geeksforgeeks.org/problems/first-non-repeating-character-in-a-stream1216/1",
                article: "#Medium #Adobe #Yahoo"
            },
            {
                q: "Next Smaller Element",
                link: "https://www.geeksforgeeks.org/problems/help-classmates--141631/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium"
            }
        ]
        
       },
      {
        type:"Graphs",
        questions: [
            {
                q: "Create a Graph, print it",
                link: "https://www.google.co.in/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwillqiz37eGAxX4a2wGHTIaBYgQFnoECBYQAQ&url=https%3A%2F%2Fwww.geeksforgeeks.org%2Fgraph-and-its-representations%2F&usg=AOvVaw0j0wMdLimPpgh8gGRh71H2&opi=89978449",
                article: "#Easy"
            },
            {
                q: "Implement BFS algorithm",
                link: "https://www.geeksforgeeks.org/problems/bfs-traversal-of-graph/1",
                article: "#Easy"
            },
            {
                q: "Implement DFS Algo",
                link: "https://www.geeksforgeeks.org/problems/depth-first-traversal-for-a-graph/1",
                article: "#Easy"
            },
            {
                q: "Detect Cycle in Directed Graph using BFS/DFS Algo",
                link: "https://www.geeksforgeeks.org/problems/detect-cycle-in-a-directed-graph/1",
                article: "#Medium"
            },
            {
                q: "Detect Cycle in UnDirected Graph using BFS/DFS Algo",
                link: "https://www.geeksforgeeks.org/problems/detect-cycle-in-a-directed-graph/1",
                article: "#Medium #Adobe #BankBazaar"
            },
            // {
            //     q: "Search in a Maze",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Minimum Step by Knight",
                link: "https://www.geeksforgeeks.org/problems/steps-by-knight5927/1",
                article: "#Medium #Intuit #Linkedin"
            },
            {
                q: "Flood fill algo",
                link: "https://leetcode.com/problems/flood-fill/",
                article: "#Easy"
            },
            {
                q: "Clone a graph",
                link: "https://leetcode.com/problems/clone-graph/",
                article: "#Medium"
            },
            {
                q: "Number of Operations to Make Network Connected",
                link: "https://leetcode.com/problems/number-of-operations-to-make-network-connected/description/",
                article: "#Medium"
            },
            {
                q: "Word Ladder",
                link: "https://leetcode.com/problems/word-ladder/description/",
                article: "#Hard"
            },
            {
                q: "Dijkstra algo",
                link: "https://www.geeksforgeeks.org/problems/implementing-dijkstra-set-1-adjacency-matrix/1",
                article: "#Medium #Flipkart #Microsoft"
            },
            {
                q: "Implement Topological Sort",
                link: "https://www.geeksforgeeks.org/problems/topological-sort/1",
                article: "#Medium #D-E-Shaw #Visa"
            },
            {
                q: "Minimum time taken by each job to be completed given by a Directed Acyclic Graph",
                link: "https://www.geeksforgeeks.org/problems/minimum-time-taken-by-each-job-to-be-completed-given-by-a-directed-acyclic-graph/1",
                article: "#Medium "
            },
            {
                q: "Find whether it is possible to finish all tasks or not from given dependencies",
                link: "https://www.geeksforgeeks.org/problems/prerequisite-tasks/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium #Twitter #Apple"
            },
            {
                q: "Find the no. of Islands",
                link: "https://www.geeksforgeeks.org/problems/find-the-number-of-islands/1",
                article: "#Medium #Microsoft #OYO Rooms"
            },
            {
                q: "Alien Dictionary",
                link: "https://www.geeksforgeeks.org/problems/alien-dictionary/1",
                article: "#Hard #Walmart #Google"
            },
            // {
            //     q: "Implement Kruskal's Algorithm",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Implement Prim’s Algorithm",
                link: "https://www.geeksforgeeks.org/problems/minimum-spanning-tree/1",
                article: "#Medium #Samsung #Cisco"
            },
            {
                q: "Total no. of Spanning tree in a graph",
                link: "https://www.geeksforgeeks.org/problems/total-number-of-spanning-trees-in-a-graph/1",
                article: "#Hard"
            },
            {
                q: "Implement Bellman Ford Algorithm",
                link: "https://www.geeksforgeeks.org/problems/distance-from-the-source-bellman-ford-algorithm/1",
                article: "#Medium #Amazon #Microsoft"
            },
            {
                q: "Implement Floyd Warshall Algorithm",
                link: "https://www.geeksforgeeks.org/problems/implementing-floyd-warshall2042/1",
                article: "#Medium #Samsung"
            },
            {
                q: "Travelling Salesman Problem",
                link: "https://www.geeksforgeeks.org/problems/travelling-salesman-problem2732/1",
                article: "#Hard #Google #Opera"
            },
            {
                q: "Graph Colouring Problem",
                link: "https://www.hackerearth.com/problem/algorithm/color-the-graph-e140de5a/",
                article: ""
            },
            {
                q: "Snake and Ladders Problem",
                link: "https://leetcode.com/problems/snakes-and-ladders/",
                article: "#Medium #OYO Rooms #MAQ Software"
            },
           
            {
                q: "Bridge edge in a graph",
                link: "https://www.geeksforgeeks.org/problems/bridge-edge-in-graph/1",
                article: "#Medium #Microsoft #OYO Rooms"
            },
            // {
            //     q: "Check whether a graph is Bipartite or Not",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Detect Negative cycle in a graph",
                link: "https://www.geeksforgeeks.org/problems/negative-weight-cycle3504/1",
                article: "#Medium #Cisco"
            },
            {
                q: "Longest path in a Directed Acyclic Graph",
                link: "https://www.geeksforgeeks.org/problems/longest-path-in-a-directed-acyclic-graph/1",
                article: "#Hard "
            },
            {
                q: "Journey to the Moon",
                link: "https://www.hackerrank.com/challenges/journey-to-the-moon/problem",
                article: "#Hard "
            },
            {
                q: "Cheapest Flights Within K Stops",
                link: "https://leetcode.com/problems/cheapest-flights-within-k-stops/description/",
                article: "#Medium"
            },
            {
                q: "Oliver and the Game",
                link: "https://www.hackerearth.com/practice/algorithms/graphs/topological-sort/practice-problems/algorithm/oliver-and-the-game-3/",
                article: "#Medium"
            },
            {
                q: "Water Jug problem",
                link: "https://www.geeksforgeeks.org/problems/two-water-jug-problem3402/1",
                article: "#Medium"
            },
            // {
            //     q: "Find if there is a path of more than k length from a source",
            //     link: "https://www.geeksforgeeks.org/problems/two-water-jug-problem3402/1",
            //     article: ""
            // },
            {
                q: "M-Colouring Problem",
                link: "https://www.geeksforgeeks.org/problems/m-coloring-problem-1587115620/1",
                article: "#Medium"
            },
            {
                q: "Minimum edges to reverse to make path from source to destination",
                link: "https://www.geeksforgeeks.org/problems/minimum-edges/1",
                article: "#Medium"
            },
            // {
            //     q: "Paths to travel each nodes using each edge (Seven Bridges)",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Vertex Cover Problem",
                link: "https://www.geeksforgeeks.org/problems/vertex-cover/1",
                article: "#Hard"
            },
            // {
            //     q: "Chinese Postman or Route Inspection",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Number of Triangles in a Directed and Undirected Graph",
                link: "https://www.geeksforgeeks.org/problems/number-of-triangles/1",
                article: "#Easy"
            },
            // {
            //     q: "Minimise the cashflow among a given set of friends who have borrowed money from each other",
            //     link: "",
            //     article: ""
            // },
            // {
            //     q: "Two Clique Problem",
            //     link: "",
            //     article: ""
            // }
        ]
        
       },
      {
        type:"Binary Search Tree",
        questions: [
            {
                q: "Find a value in a BST",
                link: "https://leetcode.com/problems/search-in-a-binary-search-tree/description/",
                article: ""
            },
            {
                q: "Deletion of a node in a BST",
                link: "https://leetcode.com/problems/delete-node-in-a-bst/",
                article: ""
            },
            {
                q: "Find min and max value in a BST",
                link: "https://www.geeksforgeeks.org/problems/minimum-element-in-bst/1",
                article: ""
            },
            {
                q: "Find inorder successor and inorder predecessor in a BST",
                link: "https://www.geeksforgeeks.org/problems/predecessor-and-successor/1",
                article: "#Medium"
            },
            {
                q: "Check if a tree is a BST or not",
                link: "https://leetcode.com/problems/validate-binary-search-tree/",
                article: "#Medium"
            },
            {
                q: "Populate Inorder successor of all nodes",
                link: "https://leetcode.com/problems/populating-next-right-pointers-in-each-node/description/",
              
                article: "#Medium"
              
            },
            {
                q: "Find LCA of 2 nodes in a BST",
                link: "https://leetcode.com/problems/lowest-common-ancestor-of-a-binary-search-tree/",
                article: " #Flipkart #Accolite #Easy"
            },
            {
                q: "Construct BST from preorder traversal",
                link: "https://leetcode.com/problems/construct-binary-search-tree-from-preorder-traversal/description/",
                article: "#Medium"
            },
            {
                q: "Convert Binary tree into BST",
                link: "https://www.geeksforgeeks.org/problems/binary-tree-to-bst/1",
                article: "#Easy #Amazon #Adobe"
            },
            {
                q: "Convert a normal BST into a Balanced BST",
                link: "https://www.geeksforgeeks.org/problems/normal-bst-to-balanced-bst/1",
                article: "#Medium #Paytm"
            },
            {
                q: "Merge two BST [ V.V.V>IMP ]",
                link: "https://leetcode.com/problems/merge-two-binary-trees/description/",
                article: "#Easy "
            
            },
            {
                q: "Find Kth largest element in a BST",
                link: "https://www.geeksforgeeks.org/problems/kth-largest-element-in-bst/1",
                article: "#Easy #Uber"
            },
            // {
            //     q: "Find Kth smallest element in a BST",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Count pairs from 2 BST whose sum is equal to given value “X”",
                link: "https://www.geeksforgeeks.org/count-pairs-from-two-bsts-whose-sum-is-equal-to-a-given-value-x/",
                article: "#Easy"
            },
            {
                q: "Find the median of BST in O(n) time and O(1) space",
                link: "https://www.geeksforgeeks.org/problems/median-of-bst/1",
                article: "#Easy #Amazon"
            },
            {
                q: "Count BST nodes that lie in a given range",
                link: "https://leetcode.com/problems/range-sum-of-bst/",
                article: "#Easy"
            },
            {
                q: "Replace every element with the least greater element on its right",
                link: "https://leetcode.com/problems/replace-elements-with-greatest-element-on-right-side/description/",
                article: "#Easy"
            },
            {
                q: "Given “n” appointments, find the conflicting appointments",
                link: "https://www.naukri.com/code360/library/given-n-appointments-find-all-conflicting-appointments",
                article: "#Medium"
            },
            {
                q: "Check preorder is valid or not",
                link: "https://www.naukri.com/code360/problems/check-if-preorder-traversal-is-valid_1164410",
                article: "Medium",
            },
            {
                q: "Check whether BST contains Dead end",
                link: "https://www.naukri.com/code360/problems/check-for-dead-end-in-a-bst_1169457",
                article: "#Medium"
            },
            {
                q: "Largest BST in a Binary Tree [ V.V.V.V.V IMP ]",
                link: "https://www.geeksforgeeks.org/problems/largest-bst/1",
                article: "#Medium #Microsoft #Samsung"
            },
            {
                q: "Flatten BST to sorted list",
                link: "https://www.geeksforgeeks.org/problems/flatten-bst-to-sorted-list--111950/1",
                article: "#Medium #Adobe #Amazon"
            }
        ]
       },
       {
        type:"Heap",
        questions: [
            {
                q: "Implement a Maxheap/MinHeap using arrays and recursion.",
                link: "https://www.geeksforgeeks.org/building-heap-from-array/",
                article: "#Easy"
            },
            {
                q: "Sort an Array using heap. (HeapSort)",
                link: "https://www.geeksforgeeks.org/problems/heap-sort/1",
                article: "#Medium #SAP Labs #Visa"
            },
            // {
            //     q: "Maximum of all subarrays of size k.",
            //     link: "",
            //     article: ""
            // },
            // {
            //     q: "“K” largest element in an array",
            //     link: "",
            //     article: ""
            // },
            // {
            //     q: "Kth smallest and largest element in an unsorted array",
            //     link: "",
            //     article: ""
            // },
            {
                q: "Merge “K” sorted arrays. [ IMP ]",
                link: "",
                article: ""
            },
            {
                q: "Merge 2 Binary Max Heaps",
                link: "https://www.geeksforgeeks.org/problems/merge-two-binary-max-heap0144/1",
                article: "#Easy "
            },
            {
                q: "Kth largest sum continuous subarrays",
                link: "https://www.geeksforgeeks.org/problems/k-th-largest-sum-contiguous-subarray/1",
                article: "#Medium"
            },
            {
                q: "Leetcode- reorganize strings",
                link: "https://leetcode.com/problems/reorganize-string/",
                article: "#Medium"
            },
            {
                q: "Merge “K” Sorted Linked Lists [V.IMP]",
                link: "https://leetcode.com/problems/merge-k-sorted-lists/",
                article: "#Hard"
            },
            {
                q: "Smallest range in “K” Lists",
                link: "https://leetcode.com/problems/smallest-range-covering-elements-from-k-lists/",
                article: "#Hard"
            },
            {
                q: "Median in a stream of Integers",
                link: "https://leetcode.com/problems/find-median-from-data-stream/",
                article: "#Hard"
            },
            {
                q: "Check if a Binary Tree is Heap",
                link: "https://www.geeksforgeeks.org/problems/is-binary-tree-heap/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium #Hike"
            },
            {
                q: "Connect “n” ropes with minimum cost",
                link: "https://www.naukri.com/code360/problems/connect-n-ropes-with-minimum-cost_630476",
                article: "#Easy"
            },
            {
                q: "Convert BST to Min Heap",
                link: "https://leetcode.com/problems/convert-bst-to-greater-tree/",
                article: "#Medium "
            },
            {
                q: "Convert min heap to max heap",
                link: "https://www.geeksforgeeks.org/problems/convert-min-heap-to-max-heap-1666385109/1",
                article: "#Medium #Microsoft"
            },
            {
                q: "Rearrange characters in a string such that no two adjacent are same.",
                link: "https://www.geeksforgeeks.org/problems/rearrange-characters4649/0",
                article: "#Medium #Amazon"
            }
        ]
       },
       {
        type:"Trie",
        questions: [
            {
                q: "Construct a trie from scratch",
                link: "",
                article: ""
            },
            {
                q: "Find shortest unique prefix for every word in a given list",
                link: "https://www.geeksforgeeks.org/problems/shortest-unique-prefix-for-every-word/1",
                article: "#Hard #Microsoft #Google"
            },
            {
                q: "Word Break Problem | (Trie solution)",
                link: "https://www.geeksforgeeks.org/problems/word-break-trie--141631/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Hard #Google #IBM"
            },
            {
                q: "Given a sequence of words, print all anagrams together",
                link: "https://www.geeksforgeeks.org/problems/print-anagrams-together/1?itm_source=geeksforgeeks&itm_medium=article&itm_campaign=bottom_sticky_on_article",
                article: "#Medium #Snapdeal #Goldman Sachs"
            },
            {
                q: "Print unique rows in a given boolean matrix",
                link: "https://www.geeksforgeeks.org/problems/unique-rows-in-boolean-matrix/1",
                article: "#Easy #Zoho #Amazon"
            },
            {
                q: "Implement a Phone Directory",
                link: "https://www.geeksforgeeks.org/problems/phone-directory4628/1",
                article: "#Hard #Microsoft #Snapdeal"
            }
        ]
        
       },

    ]

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleHover = (index) => {
      setHoveredIndex(index);
    };

  return (
    <div>
    <section className="bg-gray-100">
      <div className="container px-6 py-10 mx-auto">
        <h1 className="text-2xl font-semibold text-left text-gray-800 lg:text-6xl">
        DSA Sheet
        </h1>
  
        <div className="mt-12 space-y-8">
          {sheet.map((item, index) => (
            <div
              key={index}
              className="border-2 border-gray-100 rounded-lg dark:border-gray-700 hover:bg-blue-50 transition-colors duration-300"
              onMouseEnter={() => handleHover(index)}
            //   data-aos="zoom-in"
              data-aos-duration="2500"
            >
              <button className="flex items-center justify-between w-full p-8">
                <h1 className="font-semibold text-2xl text-gray-700">{item.type}</h1>
                <span className="text-blue-500 bg-gray-300 rounded-full p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`w-6 h-6 transform transition-transform duration-300 ${
                      hoveredIndex === index ? 'rotate-180' : 'rotate-0'
                    }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </button>
  
              {hoveredIndex === index && (
                <div className="bg-white rounded-lg p-4 shadow-md">
                  <hr className="border-gray-700" />
                  <table className="w-full mt-4 text-left table-auto">
                    <thead>
                      <tr>
                        <th className="px-4 py-2">Question</th>
                        <th className="px-4 py-2">Practice</th>
                        {/* <th className="px-4 py-2">Article</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {item.questions.map((i, index) => (
                        <tr key={index} className="hover:bg-blue-50 transition-colors duration-300">
                          <td className="border px-4 py-2">{i.q}</td>
                       
                          <td className="border px-4 py-2">
                            <a
                              href={i.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 hover:underline"
                            >
                              Link
                            </a>
                          </td>
                          {/* <td className="border px-4 py-2">
                            <a
                              href={i.article}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 hover:underline"
                            >
                              Link
                            </a>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  </div>
  
  )
}
