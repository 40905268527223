// import React, { useState } from 'react'
// // import 'intro.js/introjs.css';
// import logom from './logom.png'

// export default function Navigation() {
//   const [fl , setFl]= useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [introInstance, setIntroInstance] = useState(null);

  

//   const [open, setOpen] = useState(false);
//   const [openp, setOpenp] = useState(false);


 


//   return (

// <>
// <div className="bg-black md:flex flex-row h-20 hidden">
// <div className='justify-center my-auto md:ml-11 ml-2 '>
// <img src={logom} className='h-20 w-20' alt='logo'/>
// </div>

// <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base z-10 justify-center">
//   <a className="mr-5 hover:text-gray-300 font-bold hover:cursor-pointer text-white" href='/'>Home</a>
//   <a className="mr-5 hover:text-gray-300 font-bold hover:cursor-pointer step1 text-white" href='/us'>About Us</a>
//   {/* <a className="mr-5 hover:text-gray-300 font-bold hover:cursor-pointer step1 text-white" href='/projectlist'>Project Library</a> */}
  
  
//   <div className="relative inline-block">
//     <button onClick={() => setOpen(!open)} className="text-white font-bold py-2 px-4 rounded mx-2 hover:bg-gray-700">
//       Programs
//     </button>
//     {open && (
//       <div className="absolute mt-2 w-48 z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 right-0 z-10">
//         {/* Adjusted the right position and added z-index */}
//         <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
//           <a href="/ml" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Artificial Intelligence</a>
//           <a href="/cyber" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Cyber Security</a>
//           <a href="/python" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Python Programming</a>
//           <a href="/Android" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Android App Development with Java</a>
//           <a href="/dscience" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Data Science</a>
//           <a href="/webdev" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Web Development</a>
//           <a href="/cloud" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Cloud Computing</a>
//         </div>
//       </div>
//     )}
//   </div>
//   <div className="relative inline-block">
//     <button onClick={() => setOpenp(!openp)} className="text-white font-bold py-2 px-4 rounded mx-2 hover:bg-gray-700">
//     Placement Resourses
//     </button>
//     {openp && (
//       <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 right-0 z-10">
//         {/* Adjusted the right position and added z-index */}
//         <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
//           <a href="/notes" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Notes</a>
//           <a href="/sheet" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">DSA sheet</a>
//           </div>
//       </div>
//     )}
//   </div>
//   <a className="mr-5 hover:text-gray-300 font-bold text-white hover:cursor-pointer step3" href='/contactus'>Contact Us</a>
// </nav>






//     <div className=' justify-center my-auto mr-11 '>
//        <button className=" md:inline-flex hidden items-center bg-blue-600 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded-md font-semibold mt-4 md:mt-0" ><a href='https://forms.gle/aZqzdEJn5b92A8Rp7'>Connect Us</a>
//       <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
//         <path d="M5 12h14M12 5l7 7-7 7"></path>
//       </svg>
//     </button>
//     </div>
   
// </div>
// <div className='bg-slate-400 flex flex-col md:hidden '>
//   <div className='flex flex-row my-2 ' onClick={()=>{setFl(!fl)}}>
//     <div className='my-auto ml-2'>
//     <img className='w-20 h-20' src={logom} alt='Codeiox'/>

//     </div>

//     <div className='ml-auto mr-2 my-auto' >
  
//     <span class="block w-10 h-2 my-1 rounded bg-white"  ></span>
//     <span class="block w-10 h-2 my-1 rounded bg-white" onClick={()=>{
//       setFl(!fl)
//       console.log('fl', fl)
//       }}></span>
//     <span className="block w-10 h-2 my-1 rounded bg-white"></span>
//     </div>
    
//   </div>
//   <nav className={fl ? 'flex flex-col font-bold mx-auto border-t-2 border-white justify-center' : 'md:flex flex-col font-bold mx-auto hidden border-t-2 border-white'}>
//   {/* <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base z-10 justify-center"> */}
//   <a className="mr-5 hover:text-gray-300 hover:cursor-pointer font-bold text-white" href='/'>Home</a>
//   <a className="mr-5 hover:text-gray-300 hover:cursor-pointer font-bold step1 text-white" href='/us'>About Us</a>
//   {/* <a className="mr-5 hover:text-gray-300 hover:cursor-pointer step1 text-white" href='/projectlist'>Project Library</a> */}

  
//   <div className="relative inline-block">
//     <button onClick={() => setOpenp(!openp)} className="text-white font-bold py-2 px-4 rounded mx-2 hover:bg-gray-700">
//       Placement Resourses
//     </button>
//     {openp && (
//       <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
//         <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
//           <a href="/notes" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Notes</a>
//           <a href="/sheet" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">DSA sheet</a>
//         </div>
//       </div>
//     )}
//   </div>
//   <div className="relative inline-block">
//     <button onClick={() => setOpen(!open)} className="text-white font-bold py-2 px-4 rounded mx-2 hover:bg-gray-700">
//       Programs
//     </button>
//     {open && (
//       <div className="absolute mt-2 w-48 rounded-md shadow-lg z-10  bg-white ring-1 ring-black ring-opacity-5">
//         <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
//           <a href="/ml" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Artificial Intelligence</a>
//           <a href="/cyber" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Cyber Security</a>
//           <a href="/python" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Python Programming</a>
//           <a href="/Android" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Android App Development with Java</a>
//           <a href="/dscience" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Data Science</a>
//           <a href="/webdev" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Web Development</a>
//           <a href="/cloud" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Cloud Computing</a>
//         </div>
//       </div>
//     )}
//   </div>
//   <a className="mr-5 hover:text-gray-300 font-bold text-white hover:cursor-pointer step3" href='/contactus'>Contact Us</a>
// </nav>



  

// </div>

// </>
//   )
// }



import React, { useState } from 'react';
import logom from './logom.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

export default function Navigation() {
  const [fl, setFl] = useState(false);
  const [open, setOpen] = useState(false);
  const [openp, setOpenp] = useState(false);

  return (
    <>
      <div className="bg-[#1b0a3b] md:flex flex-row h-20 hidden">
        <div className='justify-center my-auto md:ml-11 ml-2 '>
          <img src={logom} className='h-20 w-20' alt='logo' />
        </div>

        <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base z-10 justify-center gap-4">
          <div className="relative inline-block">
            <button onClick={() => setOpen(!open)} className="h-[40px] w-fit px-2 text-white text-lg font-bold flex items-center justify-center hover:bg-gray-700 rounded-md">
              Programs <FontAwesomeIcon icon={faChevronDown} className="ml-1 mt-1"/>
            </button>
            {open && (
              <div className="absolute mt-2 w-48 z-30 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 right-0 ">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <a href="/ml" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Artificial Intelligence</a>
                  <a href="/cyber" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Cyber Security</a>
                  <a href="/python" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Python Programming</a>
                  {/* <a href="/Android" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Android App Development with Java</a> */}
                  <a href="/dscience" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Data Science</a>
                  <a href="/webdev" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Web Development</a>
                  <a href="/cloud" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Cloud Computing</a>
                  <a href="/hr" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">HR</a>
                  <a href="/stockmarket" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Stock Market</a>
                  <a href="/businessanalysis" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Business Analysis</a>
                  <a href="/digitalmarketing" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Digital Marketing</a>
              
                  <a href="/finance" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Finance</a>
                </div>
              </div>
            )}
          </div>
          <div className="relative inline-block">
            <button onClick={() => setOpenp(!openp)} className="h-[40px] w-fit px-2 text-white text-lg font-bold flex items-center justify-center hover:bg-gray-700 rounded-md">
              Placement Resources <FontAwesomeIcon icon={faChevronDown} className="ml-1 mt-1"/>
            </button>
            {openp && (
              <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 right-0 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <a href="/notes" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Notes</a>
                  <a href="/sheet" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">DSA sheet</a>
                </div>
              </div>
            )}
          </div>
          <a className="mr-5 hover:text-gray-300 font-bold hover:cursor-pointer text-white" href='/'>Home</a>
          <a className="mx-3 hover:text-gray-300 text-lg font-bold hover:cursor-pointer step1 text-white" href='/us'>About Us</a>
          <a className="ml-2 hover:text-gray-300 text-lg font-bold text-white hover:cursor-pointer step3" href='/contactus'>Contact Us</a>
        </nav>

        <div className='justify-center my-auto mr-11 '>
          <a className="md:inline-flex hidden items-center bg-[#8A01EF] text-white border-0 py-2 px-4 focus:outline-none hover:bg-[#7401C8] rounded-md font-semibold mt-4 md:mt-0" href='https://forms.gle/aZqzdEJn5b92A8Rp7'>
            Connect Us <FontAwesomeIcon icon={faArrowRight} className='ml-2'/>
            {/* <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg> */}
          </a>
        </div>
      </div>

      <div className='bg-[#1b0a3b] flex flex-col md:hidden'>
        <div className='flex flex-row my-2 ' onClick={() => { setFl(!fl) }}>
          <div className='my-auto ml-2'>
            <img className='w-20 h-20' src={logom} alt='Codeiox' />
          </div>
          <div className='ml-auto mr-2 my-auto'>
            <span className="block w-10 h-2 my-1 rounded bg-white"></span>
            <span className="block w-10 h-2 my-1 rounded bg-white"></span>
            <span className="block w-10 h-2 my-1 rounded bg-white"></span>
          </div>
        </div>

        <nav className={fl ? 'flex flex-col font-bold mx-auto border-t-2 border-white justify-center' : 'md:flex flex-col font-bold mx-auto hidden border-t-2 border-white'}>
          <div className="relative inline-block">
            <button onClick={() => setOpen(!open)} className="text-white font-bold py-2 px-4 rounded mx-2 hover:bg-gray-700">
              Programs <FontAwesomeIcon icon={faChevronDown} className="ml-1 mt-1"/>
            </button>
            {open && (
              <div className="absolute mt-2 w-48 rounded-md shadow-lg z-30 bg-white ring-1 ring-black ring-opacity-5">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <a href="/ml" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Artificial Intelligence</a>
                  <a href="/cyber" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Cyber Security</a>
                  <a href="/python" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Python Programming</a>
                  {/* <a href="/Android" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Android App Development with Java</a> */}
                  <a href="/dscience" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Data Science</a>
                  <a href="/webdev" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Web Development</a>
                  <a href="/cloud" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Cloud Computing</a>
                  <a href="/hr" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">HR</a>
                  <a href="/finance" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Finance</a>
                  <a href="/stockmarket" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Stock Market</a>
                  <a href="/businessanalysis" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Business Analysis</a>
                  <a href="/digitalmarketing" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Digital Marketing</a>
                </div>
              </div>
            )}
          </div>
          <div className="relative inline-block">
            <button onClick={() => setOpenp(!openp)} className="text-white font-bold py-2 px-4 rounded mx-2 hover:bg-gray-700">
              Placement Resources <FontAwesomeIcon icon={faChevronDown} className="ml-1 mt-1"/>
            </button>
            {openp && (
              <div className="absolute mt-2 w-48 rounded-md shadow-lg z-30 bg-white ring-1 ring-black ring-opacity-5">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <a href="/notes" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Notes</a>
                  <a href="/sheet" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">DSA sheet</a>
                </div>
              </div>
            )}
          </div>
          <a className="mx-5 hover:text-gray-300 hover:cursor-pointer font-bold text-white" href='/'>Home</a>
          <a className="mx-5 hover:text-gray-300 hover:cursor-pointer font-bold step1 text-white" href='/us'>About Us</a>
          <a className="mx-5 hover:text-gray-300 font-bold text-white hover:cursor-pointer step3" href='/contactus'>Contact Us</a>
        </nav>
      </div>
    </>
  );
}

