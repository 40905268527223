import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Explore = () => {
    const exploreData = [
        {
            'title': 'DSA Sheets',
            'bgColor1': '#4CAF97',
            'bgColor2': '#51C6AB',
            'link': '/sheet',
        },
        {
            'title': 'Notes',
            'bgColor1': '#5A5EB7 ',
            'bgColor2': '#7075EA ',
            'link': '/notes',
        },
        {
            'title': 'Link Library',
            'bgColor1': '#AF6160 ',
            'bgColor2': '#CA8A89 ',
            'link': '/#',
        },
        {
            'title': 'Published Articles',
            'bgColor1': '#674A98 ',
            'bgColor2': '#A594C3 ',
            'link': '/#',
        },
        {
            'title': 'Artificial Intelligence',
            'bgColor1': '#25879C ',
            'bgColor2': '#54C3DB ',
            'link': '/ml',
        },
        {
            'title': 'Data Science',
            'bgColor1': '#4572B6 ',
            'bgColor2': '#789EDA ',
            'link': '/dscience',
        },
        {
            'title': 'Python Programming',
            'bgColor1': '#CC733E ',
            'bgColor2': '#EAA378 ',
            'link': '/python',
        },
        {
            'title': 'Web Development',
            'bgColor1': '#F1637C ',
            'bgColor2': '#E399A6 ',
            'link': '/webdev',
        },
    ]
    return (
        <div className='py-4' style={{background: "linear-gradient(180deg, rgba(33,9,77,1) 0%, rgba(102,68,165,1) 100%)"}}>
            <p className='text-5xl font-semibold text-white text-center mb-8'>Explore</p>
            <section className='w-fit h-fit py-4 flex flex-wrap justify-center items-center'>
                {exploreData.length > 0 ? (
                    exploreData.map((data, index) =>(
                        <a key={index} className="group shrink h-[220px] w-[510px] m-4 p-4 rounded-2xl flex flex-col text-white justify-center items-center gap-6 max-md:w-[80%] max-md:max-w-[510px]" style={{background:`linear-gradient(180deg, ${data.bgColor1} 0%, ${data.bgColor2} 100%)`}} href={data.link}>
                            <span className='text-3xl font-semibold' >{data.title}</span>
                            <button className="text-lg border border-white rounded-full py-2 px-6 transition-colors duration-500 group-hover:bg-white group-hover:text-[#2e1065] ">View more 
                            <FontAwesomeIcon icon={faArrowRight} className='ml-1'/> </button>
                        </a>
                    ))
                ) : (
                        <div>No any explore data found</div>
                    )
                }
                
            </section>
        </div>
    )
}

export default Explore