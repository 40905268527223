import "./service.css";
import { BiCheck } from "react-icons/bi";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const Service = () => {
  const serviceContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    padding: "20px",
  };

  const hrStyle = {
    width: "100%",
    margin: "20px 0px",
  };

  return (
    <div style={serviceContainerStyle} id="learn">
      <div data-aos="fade-up">
        <h1 style={{ fontFamily: 'Instagram Sans Bold' }} className="md:text-5xl text-2xl">Learn with Us</h1>
      </div>
      <div style={hrStyle}>
        <hr />
      </div>
      <div>
        <section id="services">
          <div className="container services__container md:mr-40">
            <article
              className="service"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <div className="service__head">
                <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Python Basics</h3>
              </div>

              <ul className="service__list " style={{ fontFamily: 'Instagram Sans Regular' }}>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Introduction to Python programming language</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Variables, data types, and basic operations</p>
                </li>
                <li  className="pt-3 pb-3">
                  <BiCheck className="service__list-icon" />
                  <p>
                  Control flow statements: if, elif, else, loops
                  </p>
                </li>
              <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Functions and modules</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Error handling with exceptions</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Introduction to Python libraries</p>
                </li>
                
              </ul>
            </article>
            {/* END OF Python Basics */}
            <article
              className="service"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <div className="service__head">
                <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Advanced Python Concepts</h3>
              </div>

              <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Object-oriented programming in Python</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Working with files and directories</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Regular expressions and pattern matching</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Database access using Python</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Concurrency and parallelism</p>
                </li>
              </ul>
            </article>
            {/* END OF Advanced Python Concepts */}
            <article
              className="service"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <div className="service__head">
                <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Python Web Development</h3>
              </div>

              <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Introduction to web development with Python frameworks</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Building web applications using Flask or Django</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Handling HTTP requests and responses</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Authentication and authorization</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Deploying Python web applications</p>
                </li>
              </ul>
            </article>
            {/* END OF Python Web Development */}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Service;
