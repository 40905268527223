// import "./service.css";
// import { BiCheck } from "react-icons/bi";
// import AOS from "aos";
// import "aos/dist/aos.css";

// AOS.init();

// const Service = () => {
//   const serviceContainerStyle = {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     minHeight: "100vh",
//     padding: "20px",
//   };

//   const hrStyle = {
//     width: "100%",
//     margin: "20px 0px",
//   };

//   return (
//     <div style={serviceContainerStyle} id="learn">
//       <div data-aos="fade-up">
//         <h1 style={{ fontFamily: 'Instagram Sans Bold' }} className="md:text-5xl text-2xl">Learn with Us</h1>
//       </div>
//       <div style={hrStyle}>
//         <hr />
//       </div>
//       <div>
//         <section id="services">
//           <div className="container services__container md:mr-40">
//             <article
//               className="service"
//               data-aos="flip-left"
//               data-aos-easing="ease-out-cubic"
//               data-aos-duration="1000"
//             >
//               <div className="service__head">
//                 <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Cloud Computing Basics</h3>
//               </div>

//               <ul className="service__list " style={{ fontFamily: 'Instagram Sans Regular' }}>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Introduction to cloud computing and its benefits</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Overview of cloud service models: IaaS, PaaS, SaaS</p>
//                 </li>
//                 <li  className="pt-3 pb-3">
//                   <BiCheck className="service__list-icon" />
//                   <p>
//                   Understanding cloud deployment models: public, private, hybrid, and community clouds
//                   </p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Key cloud computing providers and their offerings</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Security and compliance considerations in cloud computing</p>
//                 </li>
//               </ul>
//             </article>
//             {/* END OF Cloud Computing Basics */}
//             <article
//               className="service"
//               data-aos="flip-left"
//               data-aos-easing="ease-out-cubic"
//               data-aos-duration="1000"
//             >
//               <div className="service__head">
//                 <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Cloud Infrastructure</h3>
//               </div>

//               <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Virtualization and containerization technologies</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Cloud storage solutions and data management</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Networking in the cloud: virtual networks, VPNs, and SDN</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Scalability and elasticity in cloud infrastructure</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Monitoring and management tools for cloud environments</p>
//                 </li>
//               </ul>
//             </article>
//             {/* END OF Cloud Infrastructure */}
//             <article
//               className="service"
//               data-aos="flip-left"
//               data-aos-easing="ease-out-cubic"
//               data-aos-duration="1000"
//             >
//               <div className="service__head">
//                 <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Cloud Services</h3>
//               </div>

//               <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Overview of cloud services: computing, storage, databases, networking, and more</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Building and deploying applications on cloud platforms</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Serverless computing and microservices architecture</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Integration and APIs in cloud-based applications</p>
//                 </li>
//                 <li className="pt-3 pb-3 text-xl">
//                   <BiCheck className="service__list-icon" />
//                   <p>Cloud-native development and DevOps practices</p>
//                 </li>
//               </ul>
//             </article>
//             {/* END OF Cloud Services */}
//           </div>
//         </section>
//       </div>
//     </div>
//   );
// };

// export default Service;

import "./service.css";
import { BiCheck } from "react-icons/bi";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const Service = () => {
  const serviceContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    padding: "20px",
  };

  const hrStyle = {
    width: "100%",
    margin: "20px 0px",
  };

  return (
    <div style={serviceContainerStyle} id="learn">
      <div data-aos="fade-up">
        <h1 style={{ fontFamily: 'Instagram Sans Bold' }} className="md:text-5xl text-2xl">Learn Finance with Us</h1>
      </div>
      <div style={hrStyle}>
        <hr />
      </div>
      <div>
        <section id="services">
          <div className="container services__container md:mr-40">
            <article
              className="service"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <div className="service__head">
                <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Personal Finance Basics</h3>
              </div>

              <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Introduction to personal finance and budgeting</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Understanding savings, investments, and debt management</p>
                </li>
                <li className="pt-3 pb-3">
                  <BiCheck className="service__list-icon" />
                  <p>Building a strong credit score and managing credit cards</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Retirement planning and long-term financial goals</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Tax planning and optimizing personal finances</p>
                </li>
              </ul>
            </article>
            {/* END OF Personal Finance Basics */}
            <article
              className="service"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <div className="service__head">
                <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Corporate Finance</h3>
              </div>

              <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Financial statement analysis and valuation techniques</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Capital budgeting and investment decision-making</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Understanding corporate finance structures and strategies</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Risk management and financial planning for businesses</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Mergers, acquisitions, and corporate restructuring</p>
                </li>
              </ul>
            </article>
            {/* END OF Corporate Finance */}
            <article
              className="service"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <div className="service__head">
                <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Investment Strategies</h3>
              </div>

              <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Introduction to stock market and investment fundamentals</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Portfolio management and asset allocation techniques</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Analyzing and evaluating investment opportunities</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Understanding mutual funds, ETFs, and other investment vehicles</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Risk assessment and diversification in investment planning</p>
                </li>
              </ul>
            </article>
            {/* END OF Investment Strategies */}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Service;
