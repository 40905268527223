import React from 'react'
import img from '../img.png'
import Collaborations from '../Collaborations'
import Ttu from '../ttu'
import ClientFeedBack from '../ClientFeedBack'

export default function Us() {
  return (
    <div className='my-2'>
        <img src={img}/>
        <section class="text-gray-600 body-font">
  <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
      <img class="object-cover object-center rounded" alt="hero" src="https://th.bing.com/th/id/OIP.ZLhP4DZVMPuO_HDFHqhUAwAAAA?rs=1&pid=ImgDetMain"/>
    </div>
    <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
      <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Unleashing Potential, Cultivating Skills: Your Pathway to Achievement
      </h1>
      <p class="mb-8 leading-relaxed">Codeiox is an innovative educational technology platform dedicated to empowering students through internships, specialized training programs, career counseling, and mentorship opportunities. Our mission is to bridge the divide between academic learning and the dynamic demands of today's industries. At Codeiox, we unite ambitious students with the collective wisdom and expertise of industry leaders, providing a comprehensive learning ecosystem. Our platform facilitates seamless exploration of tailored programs and enables skill development aligned with current market needs.</p>
     
    </div>
  </div>
</section>
<Collaborations/>
<Ttu/>
<ClientFeedBack />


    </div>
  )
}
