import React from 'react'

const WhyChooseUs = () => {
	const chooseData = [
		{
			'icon1':'https://corizo.in/wp-content/themes/techglobiz/images/encontr-icon01.jpg',
			'desc1':'<strong>LIVE</strong> Interactive Session',
			'icon2':'https://corizo.in/wp-content/uploads/2024/10/certificate.jpg',
			'desc2':'Industry  ratified <strong>certifications</strong>'
		},
		{
			'icon1':'https://corizo.in/wp-content/uploads/2024/10/mentor-1.jpg',
			'desc1':'Expert <strong>Industry Mentor</strong>',
			'icon2':'https://corizo.in/wp-content/uploads/2024/10/worthy-1.jpg',
			'desc2':'<strong>Portfolio</strong> worthy projects'
		},
		{
			'icon1':'https://corizo.in/wp-content/uploads/2024/10/query.png',
			'desc1':'Dedicated <strong>query session</strong>',
			'icon2':'https://corizo.in/wp-content/uploads/2024/10/comunity.jpg',
			'desc2':'Always active <strong>Community</strong>'
		},
	]

  return (
<section>
	{/* hidden on small screens, but visible on large */}
    <div className='hidden h-fit mt-20 py-16 w-screen md:flex flex-col justify-center items-center bg-sky-50'>
		<h2 className='text-center text-4xl font-bold mb-12'>Why Choose Codeiox ?</h2>

		<div className='w-[75vw] flex justify-around items-center'>
			<div className="text-xl border px-2 rounded border-black w-fit h-fit text-center">LEARNING CHALLENGES</div>
			<div className=" ">
				<img src="https://corizo.in/wp-content/themes/techglobiz/images/encounter-arw.png" alt=""/>
			</div>
			<div className="text-xl font-semibold w-fit h-fit py-2 px-2 bg-[#8A01EF] text-white rounded text-center">HOW WE ENCOUNTER </div>
		</div>

        <div className="w-screen flex justify-around gap-20 ml-24 max-xl:-mx-16">
			<div className="">
				<img src="https://corizo.in/wp-content/themes/techglobiz/images/why-corizo-homepage-gif.gif" alt="" className='w-[480px]'/>
			</div> 
			<div className="w-[50vw] flex flex-col justify-around items-center ">
				<ul className=' w-1/2 flex flex-col justify-around items-center gap-4'>
					{chooseData.map((data, index) =>(
						<li key={index} className='flex mx-4'>
							<div className='w-full max-xl:w-[40%] flex h-fit max-h-20 mx-4 px-2 py-3 justify-around items-center rounded-lg border border-[#8A01EF] bg-white '>
								<img src={data.icon1} alt="" className='rounded-full w-8 h-8 ml-1' style={{ boxShadow: "0px 0px 0px 7px rgba(129, 52, 187, 0.12)" }}/>
								<p className='w-48 text-md font-medium pl-7 pt-2 ' dangerouslySetInnerHTML={{ __html: data.desc1 }}></p>
							</div>
							<div className='w-full max-xl:w-[40%] flex h-fit max-h-20 mx-4 px-2 py-3 justify-around items-center rounded-lg border border-[#8A01EF] bg-white '>
								<img src={data.icon2} alt="" className='rounded-full w-8 h-8 ml-1' style={{ boxShadow: "0px 0px 0px 7px rgba(129, 52, 187, 0.12)" }}/>
								<p className='w-48 text-md font-medium pl-7 pt-2 ' dangerouslySetInnerHTML={{ __html: data.desc2 }}></p>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	</div>

{/* hidden on large screens, but visible on small */}
	<div className='md:hidden h-fit py-4'>
		<h2 className='text-center text-4xl font-bold mb-12'>Why Choose Codeiox ?</h2>
        <div className=" w-screen h-fit py-16 my-4 flex justify-center max-md:flex-col max-md:items-center">
			
			<div className="h-84 flex flex-col items-center justify-center mx-12">
				<div className="text-xl border px-2 rounded border-black w-fit h-fit text-center">LEARNING CHALLENGES</div>
				<img src="https://corizo.in/wp-content/themes/techglobiz/images/why-corizo-homepage-gif.gif" alt="" className=''/>
				{/* 
				</div>
				<div className="text-xl font-semibold w-fit h-10 py-1 px-2 bg-[#8A01EF] text-white rounded">HOW WE ENCOUNTER</div> */}
			</div> 

			<div className=" my-4 max-md:rotate-90 max-md:w-[20vh] max-md:mt-12 max-md:my-28  max-md:ml-0">
				<img src="https://corizo.in/wp-content/themes/techglobiz/images/encounter-arw.png" alt=""/>
			</div>

			{/* <div className=""> */}
				{/* <div className="flex flex-col justify-center items-center ml-24">
					<div className="text-xl border px-2 rounded border-black w-fit h-8">LEARNING CHALLENGES</div>
					
				</div> */}
				<div className="h-fit mb-4 w-auto flex flex-col justify-around items-center ">
					<p><div className="text-xl font-semibold w-fit h-fit py-2 px-2 bg-[#8A01EF] text-white rounded text-center -mt-6">HOW WE ENCOUNTER </div></p>
					<ul className='h-80 w-[50vw] max-md:w-[100vw] flex flex-wrap justify-center items-center gap-4 mt-4 max-md:gap-4'>
						{chooseData.map((data, index) =>(
							
							<li key={index} className='w-screen flex my-1 justify-center'>
								<div className='w-[40%] flex max-sm:flex-col h-[20%] min-h-48 px-2 py-3 mx-1 justify-around items-center rounded-lg border border-[#8A01EF] bg-white '>
									<img src={data.icon1} alt="" className='rounded-full w-8 h-8 ml-1' style={{ boxShadow: "0px 0px 0px 7px rgba(129, 52, 187, 0.12)" }}/>
									<p className='w-fit max-w-48 text-md text-center font-medium pl-7 max-sm:px-2 pt-2 text-wrap' dangerouslySetInnerHTML={{ __html: data.desc1 }}></p>
								</div>
								<div className='w-[40%] flex max-sm:flex-col h-[20%] min-h-48 px-2 py-3 mx-1 justify-around items-center rounded-lg border border-[#8A01EF] bg-white '>
									<img src={data.icon2} alt="" className='rounded-full w-8 h-8 ml-1' style={{ boxShadow: "0px 0px 0px 7px rgba(129, 52, 187, 0.12)" }}/>
									<p className='w-fit max-w-48 text-md text-center font-medium pl-7 max-sm:px-2 pt-2 text-wrap' dangerouslySetInnerHTML={{ __html: data.desc2 }}></p>
								</div>
							</li>

						))}
					</ul>
				</div>
			{/* </div> */}
		</div>
    </div>
</section>
  )
}

export default WhyChooseUs


// 3rd version





























// 2nd version

// import React from 'react'

// const WhyChooseUs = () => {
//   return (
//     <div className='mt-20 py-4 w-full flex justify-center items-center bg-sky-50'>
//         <div className="w-8/10">
// 			<h2 className='text-center text-4xl font-bold'>Why Choose Codeiox ?</h2>
// 			<div className="flex items-center justify-around mt-12 px-8">
// 				<div className="text-xl border px-2 rounded border-black w-fit h-8">LEARNING CHALLENGES</div>
// 				<div className="-mx-96">
// 					<img src="https://corizo.in/wp-content/themes/techglobiz/images/encounter-arw.png" alt=""/>
// 				</div>
// 				<div className="text-xl font-semibold w-fit h-10 py-1 px-2 bg-[#8A01EF] text-white rounded">HOW WE ENCOUNTER</div>
// 			</div> 

// 			<div className="flex justify-around items-center">
// 				<div className="flex flex-col justify-center items-center ml-24">
// 					{/* <div className="text-xl border px-2 rounded border-black w-fit h-8">LEARNING CHALLENGES</div> */}
// 					<img src="https://corizo.in/wp-content/themes/techglobiz/images/why-corizo-homepage-gif.gif" alt="" />
// 				</div>
// 				<div className="flex flex-col justify-around items-center w-fit ml-36">
// 					{/* <div className="text-xl font-semibold w-fit h-10 py-1 px-2 bg-[#8A01EF] text-white rounded text-center">HOW WE ENCOUNTER</div> */}
// 					<ul className='flex flex-wrap gap-8 p-6'>
// 						<li className='flex h-10 w-72 px-4 py-10 justify-around items-center rounded-lg border border-[#8A01EF] bg-white'>
// 							<img src="https://corizo.in/wp-content/themes/techglobiz/images/encontr-icon01.jpg" alt="" className='rounded-full' style={{ boxShadow: "0px 0px 0px 7px rgba(129, 52, 187, 0.12)" }}/>
// 							<p className='text-lg font-medium pl-7'><strong>LIVE </strong> Interactive Session </p>
// 						</li>
// 						<li className='flex h-10 w-72 px-4 py-10 justify-around items-center rounded-lg border border-[#8A01EF] bg-white'>
// 							<img src="https://corizo.in/wp-content/uploads/2024/10/certificate.jpg" alt="" className='rounded-full' style={{ boxShadow: "0px 0px 0px 7px rgba(129, 52, 187, 0.12)" }} />
// 							<p className='text-lg font-medium pl-7'>Industry  ratified <strong>certifications</strong> </p>
// 						</li>
// 						<li className='flex h-10 w-72 px-4 py-10 justify-around items-center rounded-lg border border-[#8A01EF] bg-white'>
// 							<img src="https://corizo.in/wp-content/uploads/2024/10/mentor-1.jpg" alt="" className='rounded-full' style={{ boxShadow: "0px 0px 0px 7px rgba(129, 52, 187, 0.12)" }} />
// 							<p className='text-lg font-medium pl-7'>Expert <strong>Industry Mentor</strong></p>
// 						</li>
// 						<li className='flex h-10 w-72 px-4 py-10 justify-around items-center rounded-lg border border-[#8A01EF] bg-white'>
// 							<img src="https://corizo.in/wp-content/uploads/2024/10/worthy-1.jpg" alt="" className='rounded-full' style={{ boxShadow: "0px 0px 0px 7px rgba(129, 52, 187, 0.12)" }} />
// 							<p className='text-lg font-medium pl-7'> <strong>Portfolio</strong> worthy projects</p>
// 						</li>
// 						<li className='flex h-10 w-72 px-4 py-10 justify-around items-center rounded-lg border border-[#8A01EF] bg-white'>
// 							<img src="https://corizo.in/wp-content/uploads/2024/10/query.png" alt="" className='rounded-full' style={{ boxShadow: "0px 0px 0px 7px rgba(129, 52, 187, 0.12)" }} />
// 							<p className='text-lg font-medium pl-7'>Dedicated <strong>query session</strong> </p>
// 						</li>
// 						<li className='flex h-10 w-72 px-4 py-10 justify-around items-center rounded-lg border border-[#8A01EF] bg-white'>
// 							<img src="https://corizo.in/wp-content/uploads/2024/10/comunity.jpg" alt="" className='rounded-full' style={{ boxShadow: "0px 0px 0px 7px rgba(129, 52, 187, 0.12)" }} />
// 							<p className='text-lg font-medium pl-7'>Active <strong>Community</strong> </p>
// 						</li>
// 					</ul>
// 				</div>
// 			</div>
// 		</div>
//     </div>
//   )
// }

// export default WhyChooseUs