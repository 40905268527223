// import React from "react";
// import "./clientFeedBack.css";
// import IMG from "../img/lokesh.png";
// import IMG1 from "../img/Rohit.jpg";
// import IMG2 from "../img/rahul.png";
// // import IMG2 from "../img/Rohit.png";
// // Import Swiper React components
// //import { useRef, useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";

// // import required modules
// import { Navigation } from "swiper";

// import AOS from "aos";
// import "aos/dist/aos.css";

// AOS.init();

// const data = [
//   {
//     avatar: IMG,
//     name: "Aadarsh Gupta",
//     review:
//     "Learning coding from RcCodex was a game-changer for me. The hands-on experience and excellent guidance helped me achieve amazing results, and I'm now proud to be working as an intern in a tech company. Thank you for opening doors to a bright future!"
//   },
//   {
//     avatar: IMG1,
//     name: " Rohit",
//     review:
//     "Your coding program has been a revelation for me. It equipped me with the skills and confidence to excel in the tech world. Thanks to your guidance, I secured an exciting internship opportunity, and I couldn't be more grateful for the transformative experience your course provided."    
//     },
//   {
//     avatar: IMG2,
//     name: "Rahul",
//         review:
//     "Enrolling in your coding program was a life-changing decision. The knowledge and support I received were instrumental in my journey to becoming a proficient coder.all thanks to the strong foundation your program laid for me. It's been an incredible ride!"

//         },

// ];

// function ClientFeedBack() {
//   const serviceContainerStyle = {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     minHeight: "10vh",
//     padding: "20px",
//   };

//   const hrStyle = {
//     width: "100%",
//     margin: "20px 0px",
//   };

//   return (
//     <div id="feedBackContainer">
//       <div style={serviceContainerStyle}>
//         <div data-aos="fade-up">
//           <h1 style={{ fontFamily: 'Instagram Sans Bold' }} className="md:text-5xl text-2xl">Testimonial</h1>
//         </div>
//         <div style={hrStyle}>
//           <hr />
//         </div>
//       </div>

//       <section id="feedback" data-aos="fade-left">
//         <Swiper
//           className=" feedback__container mySwiper"
//           navigation={true}
//           modules={[Navigation]}
//         >
//           {data.map(({ avatar, name, review }, index) => {
//             return (
//               <SwiperSlide key={index} className="feed">
//                 <div className="client__avatar" style={{borderRadius:'100%'}}>
//                   <img src={avatar} alt="user" width="83px" height="83px"></img>
//                 </div>
//                 <h5 className="client__name" style={{ fontFamily: 'Instagram Sans Bold' }}>{name}</h5>
//                 <small className="client__review" style={{ fontFamily: 'Instagram Sans Regular' }}>{review}</small>
//               </SwiperSlide>
//             );
//           })}
//         </Swiper>
//       </section>
//     </div>
//   );
// }

// export default ClientFeedBack;

import React, { useEffect, useRef, useState } from 'react'
import IMG from "../img/lokesh.png";
import IMG1 from "../img/Rohit.jpg";
import IMG2 from "../img/rahul.png";
// import IMG2 from "../img/Rohit.png";
import user1 from '../img/testimonial-1-sukumar.png'
import user2 from '../img/testimonial-2.png'
import user3 from '../img/testimonial-3.png'
import user4 from '../img/testimonial-4-jason.png'
import user5 from '../img/testimonial-5-shankar.png'
import user6 from '../img/testimonial-6-atharva.png'

export default function ClientFeedBack() {

  const profiles = [
    {
        'image': user1,
        'name': 'Sukumar Singh',
        'program':'Artificial Intelligence',
        'desc': "Working with AI has completely transformed our approach to problem-solving. The ability to analyze vast amounts of data quickly and derive actionable insights has allowed us to make informed decisions that were previously unimaginable. It's not just a tool; it's a game changer for our business! "
    },
    {
        'image': user2,
        'name': 'Aditya Yadav',
        'program':'Digital Marketing',
        'desc': "Digital marketing has opened doors I never thought possible for our brand. By targeting the right audience and engaging with them on platforms they frequent, we've seen our customer base grow exponentially. It's not just about selling; it's about building relationships, and digital marketing has made that easier than ever."
    },
    {
        'image': user3,
        'name': 'Adarsh Gupta',
        'program':'Web Development',
        'desc': "The impact of a well-developed website cannot be overstated. Our new site not only looks great but also functions seamlessly across devices. Our clients appreciate the user-friendly experience, and it's led to an increase in engagement and sales. Investing in web development was one of the best decisions we've made." 
    },
    {
        'image': user4,
        'name': 'Jason',
        'program':'Web Development',
        'desc': "Our company's transition to a responsive web design was a turning point. With more users accessing our site from mobile devices, the revamped layout has significantly improved user satisfaction and retention. Great web development is crucial in today's digital landscape!"
    },
    {
        'image': user5,
        'name': 'Shankar',
        'program':'Cyber Security',
        'desc': "In today's digital age, the importance of cybersecurity cannot be ignored. Our company faced several threats, but thanks to our robust security measures, we were able to thwart attacks and protect our clients' data. It's not just about compliance; it's about building trust."
    },
    {
        'image': user6,
        'name': 'Atharva',
        'program':'Blockchain',
        'desc': "Exploring blockchain technology has been enlightening. The potential for transparency and security in transactions is revolutionary. It has not only streamlined our operations but also increased trust with our clients. I'm excited to see how blockchain will continue to evolve and disrupt industries."
    },
  ]

  const [cards, setCards] = useState(profiles);
  const cardContainerRef = useRef(null);
  const [array, setArray] = useState(profiles.slice(0, 4));

  // Function to determine the number of profiles to display based on screen size
  const getProfilesCount = () => {
    const width = window.innerWidth;
    if (width <= 425) return 2;  // Show 2 profiles for screens 425px or smaller
    if (width <= 768) return 4;  // Show 3 profiles for screens 768px or smaller
    return 5;                     // Show 4 profiles for larger screens
  };

  // Update the array when the component mounts and on window resize
  useEffect(() => {
    const handleResize = () => {
      setArray(cards.slice(0, getProfilesCount())); // Update array based on current window size
    };

    // Initialize array with the correct slice
    setArray(cards.slice(0, getProfilesCount()));

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [cards]);

  // Function to add a new card and rotate the cards
  const addNewCard = () => {
    setCards((prevCards) => {
      const updatedCards = [...prevCards];
      const firstCard = updatedCards.shift(); // Remove the first card
      updatedCards.push(firstCard); // Add it to the end

      setArray(updatedCards.slice(0, getProfilesCount())); // Update the displayed array
      return updatedCards;
    });

    // Animation logic
    if (cardContainerRef.current) {
      cardContainerRef.current.style.transition = 'none';
      // cardContainerRef.current.style.transform = 'translateX(10%)';
      void cardContainerRef.current.offsetHeight; // Trigger reflow

      let translateValue;
      //  translateValue = window.innerWidth < 425 ? -170 : -270;
      if (window.innerWidth <= 320) {
        translateValue = -325;
        cardContainerRef.current.style.transform = 'translateX(0%)';
      }
      else if (window.innerWidth <= 375) {
        translateValue = -375;
        cardContainerRef.current.style.transform = 'translateX(0%)';
      }
      else if (window.innerWidth <= 428) {
        translateValue = -420;
        cardContainerRef.current.style.transform = 'translateX(0%)';
      } 
      else if (window.innerWidth <= 768) {
        translateValue = -375;
        cardContainerRef.current.style.transform = 'translateX(5%)';
      } 
      else {
        translateValue = -510; // Default case for width >= 768
        cardContainerRef.current.style.transform = 'translateX(0%)';
      }

      setTimeout(() => {
        if (cardContainerRef.current) {
          cardContainerRef.current.style.transition = 'transform 1s linear';
          cardContainerRef.current.style.transform = `translateX(${translateValue}px)`;
        }
      }, 50);
    }
  };

  // Set interval for adding new cards
  useEffect(() => {
    const interval = setInterval(addNewCard, 3000);
    return () => clearInterval(interval); // Clean up on component unmount
  }, []);

  return (
    <div>
       <div className='mt-20 text-center text-5xl font-semibold'> <span className='bg-clip-text text-transparent' style={{backgroundImage: "linear-gradient(95deg, #6F23A7 25%, #D03C20 100%)"}}> Testimonials </span> </div>

      <section className="overflow-x-hidden w-fit flex justify-center py-10 ">  
        <div className="flex gap-4 cards max-md:gap-1" ref={cardContainerRef}>
          {array.map((card, index) => ( 
            <div key={index} className='testimonial-track h-fit overflow-hidden py-4 px-2 max-md:'>
              <div className='testimonial-card w-[480px] max-md:w-[47vw] max-sm:w-[95vw] mt-28  border-2 border-fuchsia-800 rounded-3xl flex flex-col justify-around items-center text-lg relative'>
                <img
                  src={card.image} 
                  alt="profile-img" 
                  className='h-36 w-36 rounded-full absolute left-[35%] max-[375px]:left-[30%] -top-[35%]  max-md:-top-[30%]'
                />
                <div className="mt-10 flex flex-col justify-center items-center">
                  <h2 className='text-2xl font-bold'>{card.name}</h2>
                  <span className='italic text-base'>{card.program}</span>
                  <p className='p-4 h-fit' dangerouslySetInnerHTML={{ __html: card.desc }}></p>
                </div>
              </div>
            </div>
          ))} 
        </div>
      </section>



      {/* <section class="text-gray-600 body-font">
      <h1 style={{ fontFamily: 'Instagram Sans Bold' }} className="md:text-5xl text-2xl text-center mt-14">Testimonial</h1>

      <div class="container px-5 py-24 mx-auto">
  <div class="flex flex-wrap -m-4">
    <div class="lg:w-1/3 md:w-1/2 w-full p-4">
      <div class="h-full text-center border-2 border-blue-600 rounded-lg py-3 px-2">
        <img alt="testimonial" class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={IMG}/>
        <p class="leading-relaxed">
        Learning coding from Codeiox was a game-changer for me. The hands-on experience and excellent guidance helped me achieve amazing results, and I'm now proud to be working as an intern in a tech company. Thank you for opening doors to a bright future!          </p>
        <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
        <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm">Aadarsh Gupta</h2>
        <p class="text-blue-600">Student</p>
      </div>
    </div>
    <div class="lg:w-1/3 md:w-1/2 w-full p-4">
      <div class="h-full text-center border-2 border-blue-600 rounded-lg py-3 px-2">
        <img alt="testimonial" class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={IMG1}/>
        <p class="leading-relaxed">
        Your coding program has been a revelation for me. It equipped me with the skills and confidence to excel in the tech world. Thanks to your guidance, I secured an exciting internship opportunity, and I couldn't be more grateful for the transformative experience your course provided.          </p>
        <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
        <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm">Rohit Yadav</h2>
        <p class="text-blue-600">UI Develeoper</p>
      </div>
    </div>
    <div class="lg:w-1/3 md:w-1/2 w-full p-4">
      <div class="h-full text-center border-2 border-blue-600 rounded-lg py-3 px-2">
        <img alt="testimonial" class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={IMG2}/>
        <p class="leading-relaxed">
        Enrolling in your coding program was a life-changing decision. The knowledge and support I received were instrumental in my journey to becoming a proficient coder.all thanks to the strong foundation your program laid for me. It's been an incredible ride!          </p>
        <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
        <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm">Rahul</h2>
        <p class="text-blue-600">Student</p>
      </div>
    </div>
  </div>
</div>

</section> */}
    </div>
  )
}

