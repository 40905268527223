import React from 'react'
import './Home.css'
import HomeCard from '../../link_lib/HomeCards/HomeCard'
const Linkhome = () => {
  return (
    <div className="flex flex-wrap">
        <HomeCard/>
    </div>
  )
}

export default Linkhome

