import React from 'react'
import './Collaborations.css'
import lpu from '../img/lpulogo.png'
import dtu from '../img/dtulogo.jpg'
import ims from '../img/ims.jpeg'
import cu from '../img/cu.jpg'
import yb from '../img/yours.jpeg'
import mgkvp from '../img/mgkv.png'
import rclogo from './rclogo.png'
import iitJammu from '../img/iit-Jammu.jpg'
import samUniv from '../img/sam-university.jpg'
import googleEdu from '../img/google-edu.jpg'
import accentureLogo from '../img/accenture.jpg'
import wiproLogo from '../img/wipro.jpg'
import paypal from '../img/Paypal.png'
import airtel from '../img/airtel.png'
import razorpay from '../img/razorpay.png'

export default function Collaborations() {
  //  let data=[
  //       {
  //           img:lpu
  //       },
  //       {
  //           img:dtu
  //       },
  //       {
  //           img:ims
  //       },
  //       {
  //           img:yb
  //       },
  //       {
  //           img:mgkv
  //       },
  //       {
  //           img:rclogo
  //       },
  //   ]
  //   const boxStyle = {
  //       boxShadow: '0 4px 8px rgba(255, 10, 10, 0.5)', // Adjust values as needed
  //       padding: '20px', // Add other styling as needed
  //     };



  return (
   
    <section className='overflow-x-hidden mb-16'>
      <h1 className="text-4xl font-bold mt-36 text-center">Collaborations & Students Alumni</h1>

      <div className="slider-left flex gap-10 mt-16">
          <img src={lpu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={accentureLogo} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={dtu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={ims} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={googleEdu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={yb} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={mgkvp} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={rclogo} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={wiproLogo} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={iitJammu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={samUniv} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={paypal} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={airtel} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={razorpay} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
        {/* repeating the images */}
          <img src={lpu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={accentureLogo} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={dtu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={ims} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={googleEdu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={yb} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={mgkvp} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={rclogo} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={wiproLogo} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={iitJammu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={samUniv} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={paypal} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={airtel} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={razorpay} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
      </div>
      <div className="slider-right flex gap-10 mt-8">
      <img src={lpu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={accentureLogo} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={dtu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={ims} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={googleEdu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={yb} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={mgkvp} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={rclogo} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={wiproLogo} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={iitJammu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={samUniv} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={paypal} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={airtel} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={razorpay} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
        {/* repeating the images */}
          <img src={lpu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={accentureLogo} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={dtu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={ims} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={googleEdu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={yb} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={mgkvp} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={rclogo} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={wiproLogo} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={iitJammu} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={samUniv} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={paypal} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={airtel} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
          <img src={razorpay} alt="sliding" className='slide border-2 h-[100px] w-fit rounded-lg'/>
      </div>
      <div>
      </div>
    </section>


   
//     <section className="text-gray-600 body-font">
//     <div className="flex flex-col text-center w-full mt-3">
//       <h2 style={{ fontFamily: 'Instagram Sans Bold'}} className="md:text-5xl text-2xl md:m-5 text-black">Collaborations & Students Alumin</h2>
//    </div>
  
    
//    <div className="flex flex-wrap">
//   {data.map((val, index) => (
//     <div key={index} className=" md:w-1/3 p-4 mx-auto">
//       <div className='h-1/2 w-1/2 mx-auto my-auto' >
//       <img
//         className='h-fit w-fit rounded-md'
//         src={val.img}
//         alt={`Image ${index}`}
//         style={boxStyle}
//       />
//       </div>
//     </div>
//   ))}
// </div>


// </section>
          
  )
}
