import React from 'react'
import bgimg from './bgimg.png'
import ph from './phone.png'

export default function Belowhero() {

    const sectionStyle = {
    backgroundImage: `url(${bgimg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  return (
    <section className="text-gray-600 body-font overflow-hidden" style={sectionStyle}>
  <div className="container px-5 py-24 mx-auto">
    <div className="lg:w-4/5 mx-auto flex flex-wrap">
      {/* <img alt="ecommerce" className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src="https://dummyimage.com/400x400"/> */}
      <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0 ml-auto bg-slate-50 p-8 rounded">
        <h2 className="text-sm font-medium text-gray-500 tracking-widest">About Us</h2>
        <h1 className="text-gray-900 text-4xl title-font font-bold mb-1">Taking a Step Towards a Successful You</h1>
        <div className="flex mb-4">
          
          
        </div>
        <p className="leading-relaxed">
        Codeiox is a tech company that supports students by connecting them with internships, training programs, career advice, and mentors. Our goal is to link what you learn in school with what the industry needs right now.
        </p>
        <div className="mt-6 py-4 rounded border-b-2 bg-slate-500 border-gray-100 mb-5 md:w-2/3 flex  items-center">
    <div className="flex ml-6 my-auto bg-slate-300 p-6 rounded md:w-1/3">
        <img src={ph} className='md:w-10 md:h-10 w-5 h-5' />
    </div>
    <div>
      <p className='text-slate-200 ml-3 font-bold'>Call Us:</p>
      {/* <p className='text-slate-200 ml-3 font-semibold '>+91 884-035-6074  812-1777057</p> */}
      <p className='text-slate-200 ml-3 font-semibold '>+91 812-177-7057</p>
    </div>
</div>

       
      </div>
    </div>
  </div>
</section>
  )
}
