// import React from 'react'
// import Service from "./Service";
// import Project from "./Project";
// import ClientFeedBack from "./ClientFeedBack";
// import BookUs from "./BookUs";

// import FounderPage from "./FounderPage";
// import Courses from "./courses";
// import Body from "./Body"
// import Collaborations from './Collaborations';
// import img from'./img.png'
// import Belowhero from './belowhero';
// import Ttu from './ttu';
// import Offercourse from './offercourse';
// import imgstudent from '../img/imgstudent.png'
// export default function Homepage() {
//   return (
//     <div>
//       <Body />
//       {/* <Service /> */}
//       <Belowhero />
//       <Collaborations />
//       <Ttu />
//       {/* <Courses/> */}
//       <Offercourse />

//       {/* <Project />
//       <FounderPage /> */}
//       <img src={img} className="" /> {/* Add className="img-fluid w-100" here */}

//       <div>
//         <ClientFeedBack />
//       </div>
//       <div>
//         {/* <BookUs /> */}
//       </div>
//     </div>
//   );
// }

import React from 'react';
import Service from "./Service";
import Project from "./Project";
import ClientFeedBack from "./ClientFeedBack";
import BookUs from "./BookUs";
import FounderPage from "./FounderPage";
import Courses from "./courses";
import Body from "./Body";
import Collaborations from './Collaborations';
import img from './img.png';
import Belowhero from './belowhero';
import Ttu from './ttu';
import Offercourse from './offercourse';
import imgstudent from '../img/imgstudent.png';
import WhyChooseUs from './WhyChooseUs';
import Explore from './Explore';

export default function Homepage() {
  return (
    <div>
      <Body />
      {/* <Service /> not being used*/} 
      <Belowhero />
      <Collaborations />
      <Offercourse />
      <Explore/>
      <Ttu />

      {/* <Courses/> not being used*/}
      {/* <Project />   not being used
      <FounderPage /> not being used  */}
      <div>
        <ClientFeedBack />
      </div>
      <img src={img} className="w-full hidden md:block" alt="Main" /> {/* Hidden on small screens, shown on medium and up */}
      <img src={imgstudent} className="w-full block md:hidden" alt="Student" /> {/* Shown on small screens, hidden on medium and up */}
      <WhyChooseUs/>
      {/* <div>
        <BookUs /> not being used
      </div> */}
    </div>
  );
}
