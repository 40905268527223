import React from 'react'

export default function Cancellation() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-200 my-4">
    <div className="bg-white shadow-lg rounded-lg overflow-hidden font-sans leading-normal tracking-normal max-w-4xl mx-auto">
      <div className="container flex flex-wrap py-6">
        <div className="w-full flex flex-col items-center justify-center p-6">
          <h1 className="text-4xl text-center mb-4 font-bold text-indigo-700">Cancellation and Refund Policy for codeiox.in</h1>
          <p className="text-sm text-gray-500 mb-8">Last Updated: 13/07/24</p>
          <p className="text-lg text-gray-800 mb-6">
            Welcome to codeiox.in ("Codeiox," "we," "us," or "our"). This Cancellation and Refund Policy outlines the terms and conditions regarding the cancellation of services and the refund process for our platform. By using our services, you agree to abide by the terms outlined below.
          </p>
  
          <div className="w-full">
            <h2 className="text-2xl font-semibold text-indigo-600 mb-4">1. Cancellation of Services</h2>
            <p className="text-lg text-gray-800 mb-4">
              <strong>a. Cancellation by User:</strong> Users may cancel their subscription or request the cancellation of specific services offered by Codeiox at any time. To initiate a cancellation, users should contact our support team through the designated channels provided on our website, pre-registration fees is non-refundable.
            </p>
           
           
            <p className="text-lg text-gray-800 mb-4">
              <strong>b. Cancellation by Codeiox:</strong> Codeiox reserves the right to cancel services or subscriptions at its discretion. In such cases, we will provide notice to affected users and may offer a refund for the unused portion of the services.
            </p>
          </div>
  
          <div className="w-full">
            <h2 className="text-2xl font-semibold text-indigo-600 mb-4">2. Refund Policy</h2>
            <p className="text-lg text-gray-800 mb-4">
              <strong>a. Refund Eligibility:</strong> Refunds may be provided in certain circumstances with in 6 to 7 working days, such as:
              <br/>
              <strong>i.</strong> If the user cancels the subscription or requests a refund within a specified refund period, as communicated during the purchase.
              <br/>
              <strong>ii.</strong> If Codeiox discontinues a service or faces technical issues preventing the user from accessing paid content.
            </p>
            <p className="text-lg text-gray-800 mb-4">
              <strong>b. Refund Process:</strong> Users seeking a refund must contact our support team through the designated channels. Refund requests will be processed within a reasonable timeframe.
            </p>
            <p className="text-lg text-gray-800 mb-4">
              <strong>c. Refund Period:</strong> The refund period may vary depending on the type of service or subscription. Users are encouraged to review the specific refund policy applicable to the service or subscription they have purchased.
            </p>
          </div>
  
          <div className="w-full">
            <h2 className="text-2xl font-semibold text-indigo-600 mb-4">3. Non-Refundable Items</h2>
            <p className="text-lg text-gray-800 mb-4">
              <strong>a. Certain Services:</strong> Some services or products offered by Codeiox may be non-refundable. This will be clearly communicated to users before making a purchase.
            </p>
            <p className="text-lg text-gray-800 mb-4">
              <strong>b. Used or Consumed Services:</strong> Refunds will not be provided for services that have been used or consumed.
            </p>
          </div>
  
          <div className="w-full">
            <h2 className="text-2xl font-semibold text-indigo-600 mb-4">4. Chargebacks and Disputes</h2>
            <p className="text-lg text-gray-800 mb-4">
              Users agree not to initiate chargebacks or disputes with their financial institution without first attempting to resolve the issue through Codeiox's customer support. Chargebacks or disputes may result in the suspension or termination of the user's account.
            </p>
          </div>
  
          <div className="w-full">
            <h2 className="text-2xl font-semibold text-indigo-600 mb-4">5. Changes to the Cancellation and Refund Policy</h2>
            <p className="text-lg text-gray-800 mb-4">
              Codeiox reserves the right to update this Cancellation and Refund Policy at any time. The updated policy will be effective upon posting on our website. Users are encouraged to review this policy periodically.
            </p>
          </div>
  
          <div className="w-full">
            <h2 className="text-2xl font-semibold text-indigo-600 mb-4">6. Contact Us</h2>
            <p className="text-lg text-gray-800 mb-6">
              If you have questions or concerns about this Cancellation and Refund Policy, please contact us at '8840356074' or support@codeiox.in
            </p>
          </div>
  
          <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
        </div>
      </div>
    </div>
  </div>
  
        
  )
}
