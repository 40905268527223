// import React from "react";
// // import './App.css';
// // import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Navbar from "./components/Navbar";
// // import Service from "./components/Service";
// // import Project from "./components/Project";
// // import ClientFeedBack from "./components/ClientFeedBack";
// // import BookUs from "./components/BookUs";
// import { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

// import Hero from "./components/webdev/hero";
// import Homepage from "./components/homepage";
// import Footer from "./components/Footer"
// import Heroml from "./components/ml/hero";
// import Term from "./components/termncondition/term";
// import Policy from "./components/termncondition/Policy";
// import Connect from "./components/connect";
// import Cancellation from "./components/termncondition/cancellation";
// import  Herocy from './components/cyber/hero'
// import  Heropy from './components/python/hero'
// import  Heroroid from './components/ndroid/hero'
// import  Herods from './components/dscience/hero'
// import  Herocloud from './components/cloud/hero'
// import Us from "./components/link/about";
// import Contactus from "./components/link/contactus";
// import Prebooking from "./components/payment/prebooking";



// function App() {
//   const [showNavAndFooter, setShowNavAndFooter] = useState(true);

//   const navigate = useNavigate();

//   // Function to handle navigation
//   const handleNavigation = (to) => {
//     // Check if navigating to registration page
//     const [showNavAndFooter, setShowNavAndFooter] = useState(true);

//   useEffect(() => {
//     // Check if current path is '/registration'
//     if (location.pathname === '/registration') {
//       setShowNavAndFooter(false);
//     } else {
//       setShowNavAndFooter(true);
//     }
//   }, [location.pathname]);
//   };


//   return (
//     <div className="App">
//       <Router>
//         <Routes>
//           {/* Conditionally render Navbar and Footer */}
//           {showNavAndFooter && <Navbar />}
//           <Route path="/webdev" element={<Hero />} />
//           <Route path="/ml" element={<Heroml />} />
//           <Route path="/" element={<Homepage />} />
//           <Route path="/term" element={<Term />} />
//           <Route path="/policy" element={<Policy />} />
//           <Route path="/connectus" element={<Connect />} />
//           <Route path="/cancellation" element={<Cancellation />} />
//           <Route path="/cyber" element={<Herocy />} />
//           <Route path="/python" element={<Heropy />} />
//           <Route path="/Android" element={<Heroroid />} />
//           <Route path="/dscience" element={<Herods />} />
//           <Route path="/cloud" element={<Herocloud />} />
//           <Route path="/contactus" element={<Contactus />} />
//           <Route path="/us" element={<Us />} />
//           <Route path="/registration" element={<Prebooking />} />
//           {/* Conditionally render Footer */}
//           {showNavAndFooter && <Footer />}
//         </Routes>
//       </Router>
//     </div>
//   );
// }

// export default App;

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Hero from "./components/webdev/hero";
import Homepage from "./components/homepage";
import Footer from "./components/Footer";
import Heroml from "./components/ml/hero";
import Term from "./components/termncondition/term";
import Policy from "./components/termncondition/Policy";
import Connect from "./components/connect";
import Cancellation from "./components/termncondition/cancellation";
import Herocy from './components/cyber/hero';
import Heropy from './components/python/hero';
import Heroroid from './components/ndroid/hero';
import Herods from './components/dscience/hero';
import Herocloud from './components/cloud/hero';
import Us from "./components/link/about";
import Dsasheet from "./components/placement/dsasheet";
import Contactus from "./components/link/contactus";
import Prebooking from "./components/payment/prebooking";
import Postbooking from "./components/payment/post_payment";
import Studymaterial from "./components/placement/studymaterial";
import Codelist from "./components/code/codelist";
import Codepage from './components/code/codepage'
import HR from "./components/HR/hero";
import Stockmarket from "./components/stockm/hero";
import Finance from "./components/finance/hero";
import Ba from "./components/ba/hero";
import Dmarketing from "./components/dmarketing/hero";
import Linkhome from "./components/screens/Home/Home";
import Learn from "./components/screens/Learn/Learn"
import Syllabus from "./components/link_lib/Syllabus/Syllabus";


function App() {
  const [showNavAndFooter, setShowNavAndFooter] = useState(true);
  // const location = useLocation();

  // useEffect(() => {
  //   // Check if current path is '/registration'
  //   if (location.pathname === '/registration') {
  //     setShowNavAndFooter(false);
  //   } else {
  //     setShowNavAndFooter(true);
  //   }
  // }, [location.pathname]);

  return (
    <div className="App">
      <Router>
        {/* Render Navbar and Footer outside Routes */}
       <Navbar />
        <Routes>
          <Route path="/webdev" element={<Hero />} />
          <Route path="/ml" element={<Heroml />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/term" element={<Term />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/connectus" element={<Connect />} />
          <Route path="/cancellation" element={<Cancellation />} />
          <Route path="/cyber" element={<Herocy />} />
          <Route path="/python" element={<Heropy />} />
          <Route path="/Android" element={<Heroroid />} />
          <Route path="/dscience" element={<Herods />} />
          <Route path="/cloud" element={<Herocloud />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/us" element={<Us />} />
          <Route path="/registration" element={<Prebooking />} />
          <Route path="/post-registration" element={<Postbooking />} />
          <Route path="/sheet" element={<Dsasheet />} />
          <Route path="/notes" element={<Studymaterial />} />
          <Route path="/hr" element={<HR/>} />
          <Route path="/finance" element={<Finance/>} />
          <Route path="/stockmarket" element={<Stockmarket/>} />
          <Route path="/businessanalysis" element={<Ba/>} />
          <Route path="/digitalmarketing" element={<Dmarketing/>} />
          <Route path="/code/:_id" element={<Codepage/>} />
          <Route path="/link_lib" element={<Linkhome/>} />
          <Route path="/learn" element={<Learn/>} />
          <Route path="/syllabus" element={<Syllabus/>} />
          {/* <Route path="/code/:_id" element={<Codepage/>} /> */}

     

        </Routes>
        {/* Render Footer outside Routes */}
        <Footer />
      </Router>
    </div>
  );
}

export default App;
