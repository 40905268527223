import "./service.css";
import { BiCheck } from "react-icons/bi";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const Service = () => {
  const serviceContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    padding: "20px",
  };

  const hrStyle = {
    width: "100%",
    margin: "20px 0px",
  };

  return (
    <div style={serviceContainerStyle} id="learn">
      <div data-aos="fade-up">
        <h1 style={{ fontFamily: 'Instagram Sans Bold' }} className="md:text-5xl text-2xl">Learn with Us</h1>
      </div>
      <div style={hrStyle}>
        <hr />
      </div>
      <div>
        <section id="services">
          <div className="container services__container md:mr-40">
            <article
              className="service"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <div className="service__head">
                <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Java Basics</h3>
              </div>

              <ul className="service__list " style={{ fontFamily: 'Instagram Sans Regular' }}>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Introduction to Java programming language</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Variables, data types, and basic operations</p>
                </li>
                <li  className="pt-3 pb-3">
                  <BiCheck className="service__list-icon" />
                  <p>
                  Control flow statements: if, else, loops
                  </p>
                </li>
              <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Functions and methods</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Error handling with exceptions</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Introduction to object-oriented programming</p>
                </li>
                
              </ul>
            </article>
            {/* END OF Java Basics */}
            <article
              className="service"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <div className="service__head">
                <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Android Fundamentals</h3>
              </div>

              <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Overview of Android app development</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Understanding Android architecture and components</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>User interface design with XML layouts</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Handling user input and events</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Working with activities and intents</p>
                </li>
              </ul>
            </article>
            {/* END OF Android Fundamentals */}
            <article
              className="service"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <div className="service__head">
                <h3 style={{ fontFamily: 'Instagram Sans Bold' }}>Building Android Apps</h3>
              </div>

              <ul className="service__list" style={{ fontFamily: 'Instagram Sans Regular' }}>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Creating layouts and views for Android apps</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Working with RecyclerView and ListView</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Storing data locally using SQLite database</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Connecting to web services and APIs</p>
                </li>
                <li className="pt-3 pb-3 text-xl">
                  <BiCheck className="service__list-icon" />
                  <p>Deploying Android apps to Google Play Store</p>
                </li>
              </ul>
            </article>
            {/* END OF Building Android Apps */}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Service;
